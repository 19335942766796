import {Appv, Flex, String} from "@hps/hops-react";
import React from "react";
import {connect} from "react-redux";

/**
 * F12 app version information
 *
 * @package HOPS
 * @subpackage F12
 * @copyright Heritage Operations Processing Limited
 */
const F12Version = () => {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	return (
		<Flex gap={0}>
			<String
				color="textSecondary"
				str="App Version" />
			<String
				color="textSecondary"
				str={Appv}
				variant="subtitle2" />
		</Flex>
	);

};

export default connect(({App}) => ({App}))(F12Version);

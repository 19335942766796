import {Button, Checkbox, Flex, Loader, SnackbarService, String, Table} from "@hps/hops-react";
import {useCallback, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";
import OrderService from "Services/OrderService";
import {getOrderableTypeLabel} from "Utils/OrderableTypesUtils";


/**
 * Render a tab with unbatched items, which can be put in a batch
 */
const OrderStationeryFulfilmentUnbatched = props => {

	const {
		AuthUser,
		Items,
		OnFetchNewData
	} = props;

	const [selectedUnbatchedItems, setSelectedUnbatchedItems] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Create a Batch
	 */
	const handleCreateBatch = useCallback(async () => {

		setIsSubmitting(true);

		await OrderService.createStationeryFulfilmentBatch(
			selectedUnbatchedItems,
			AuthUser.Id
		)
			.then(() => OnFetchNewData?.())
			.catch(error => SnackbarService.snack(error))
			.finally(() => {
				setSelectedUnbatchedItems([]);
				setIsSubmitting(false);
			});

	}, [AuthUser, OnFetchNewData, selectedUnbatchedItems]);


	const handleToggleConfirmCheckbox = (value, key) => {

		/** Add item to array */
		if (value === true && !selectedUnbatchedItems.includes(key)) {
			setSelectedUnbatchedItems([
				...selectedUnbatchedItems,
				key
			]);
		}

		/** Recreate array, filtering out item */
		else if (value === false && selectedUnbatchedItems.includes(key)) {
			setSelectedUnbatchedItems([
				...selectedUnbatchedItems.filter(x => x !== key)
			]);
		}

	};


	const tableFields = [
		{
			render: i => <Checkbox
				checked={selectedUnbatchedItems.includes(i.Id.toString())}
				disabled={isSubmitting}
				name={i.Id.toString()}
				onChange={handleToggleConfirmCheckbox} />
		},
		{
			label: "Ref.",
			render: i => <String str={i.Id} />
		},
		{
			label: "For Items",
			render(i) {
				return (
					<>
						{i.DeliversItems.map((item, key) => <Flex key={key} gap={0}>
							<String bold={true} str={`${item.Qty}x ${getOrderableTypeLabel(item.ItemType)}`} />
							<String noFlex={true} str={item.Description} />
						</Flex>)}
					</>
				);
			}
		}
	];


	return (
		<Flex>
			<Table
				items={Items}
				fields={tableFields} />
			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="flex-end">
				{isSubmitting && <Loader size={20} />}
				<Button
					disabled={selectedUnbatchedItems.length === 0}
					label="Add to Batch"
					loading={isSubmitting}
					onClick={handleCreateBatch}
					variant="contained" />
			</Flex>
		</Flex>
	);

};

export default withAuthUser(OrderStationeryFulfilmentUnbatched);

import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistReducer, persistStore} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

import {createReducer} from "./Reducer.js";
import State from "./State.js";


/**
 * `redux-persist`
 * Client applications can manipulate this by setting store.persistConfig then injecting a reducer.
 */
const persistConfig = {
	key: "app",
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		"AdminUser",
		"App",
		"AuthUser",
		"Basket",
		"Cache",
		"Device",
		"Gateway",
		"Orders",
		"Registration",
		"Settings",
		"StockControl",
		"Theme",
		"Ui"
	]
};

/**
 * `redux-state-sync`
 */
const rss = {
	blacklist: [
		"persist/FLUSH",
		"persist/PAUSE",
		"persist/PERSIST",
		"persist/PURGE",
		"persist/REHYDRATE"
	],
	broadcastChannelOption: {webWorkerSupport: false}
};

/**
 * Create our store
 */
const store = createStore(
	persistReducer(persistConfig, createReducer()),
	State,
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(rss)))
);

// Add an object to hold async reducers
store.asyncReducers = {};

// Add an object to hold the redux-persist config (so client can modify)
store.persistConfig = persistConfig;

/**
 * Add a function to allow an array of reducers to be injected in to the store
 * by hosted applications.
 * 
 * Waits until all the reducers have been added so the store is only recreated
 * and re-hydrated once.
 * 
 * @param {string} key The identity of the reducer
 * @param {Reducer} reducer The function to handle reducer actions
 * @param {bool} persist Add this reducer to the redux-persist whitelist
 * @returns 
 */
store.addAsyncReducers = reducers => {

	reducers.forEach(reducer => {
		// Add to redux-persist whitelist
		if (reducer.persist && !store.persistConfig.whitelist.includes(reducer.key)) store.persistConfig.whitelist.push(reducer.key);

		// Add the new reducer to the async reducers list
		store.asyncReducers[reducer.key] = reducer.reducer;
	});

	// Recreate the store reducer from the list of static and async reducers
	store.replaceReducer(persistReducer(store.persistConfig, createReducer(store.asyncReducers)));

	return store;
};


/**
 * Enable `redux-persist`
 */
const persistor = persistStore(store);

/**
 * Enable `redux-state-sync`
 */
initMessageListener(store);

export default store;
export {store as Store, persistor as StorePersistor};

import Store from "App/Store.js";
import dUpdateAvailable from "Dispatchers/dUpdateAvailable.js";

const AppUpdateCheckTask = async () => {

	const APP_VERSION = process.env.REACT_APP_APP;

	if (APP_VERSION && !Store.getState().UpdateAvailable) {

		const response = await fetch("/version.txt");
		const version = await response.text();
		const versionString = version?.trim();

		if (versionString && (versionString !== APP_VERSION)) {
			dUpdateAvailable(true);
		}

	}

};

export default AppUpdateCheckTask;

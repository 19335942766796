import {useCallback} from "react";
import {connect} from "react-redux";

import withRegistration from "Hoc/withRegistration.js";

const withAuthUser = Component => connect(({AdminUser, AuthUser}) => ({AdminUser, AuthUser}))(withRegistration(
	props => {

		const adminUser = ((props.AdminUser && props.Registration?.Users?.find(u => (u.Id === props.AdminUser.Id))) || null);
		const authUser = ((props.AuthUser && props.Registration?.Users?.find(u => (u.Id === props.AuthUser.Id))) || null);

		/**
		 * Check whether the current authentication context has a given permission.
		 *
		 * @param {Integer} pid
		 * @param {?Integer} pvar optional
		 * @return {Boolean}
		 */
		const hasPermission = useCallback((pid, pvar=0) => {

			const permissionUser = adminUser ?? authUser;

			const vars = (permissionUser?.Permissions?.[pid] || []);
			if (pvar === -1) pvar = null;

			/** 0 = must have 0 as the ONLY variable */
			if (pvar === 0) {
				return ((vars.length === 1) && (vars[0] === 0));
			}
			/** null (-1) = must have null (-1) or any non-0 variable */
			else if (pvar === null) {
				return (vars.includes(null) || !!vars.find(v => (v > 0)));
			}
			/** variable = must have null (-1) or the specific variable */
			else {
				return (vars.includes(null) || vars.includes(pvar));
			}

		}, [adminUser, authUser]);

		return (
			<Component
				{...props}
				AdminUser={adminUser}
				AuthUser={authUser}
				authed={!!authUser?.Id}
				hasPermission={hasPermission}
				permissions={authUser?.Permissions}
				supervised={!!adminUser?.Id} />
		);

	}
));

export default withAuthUser;

/* eslint-disable no-constructor-return */
import Store from "App/Store.js";
import StaticTasks from "App/Tasks.js";
import dSyncing from "Dispatchers/dSyncing.js";

/**
 * Tasker
 *
 * Background task system implementation.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Tasker {

	/**
	 * Construct an instance with a specified tasks array.
	 *
	 * @param {Array<Object>} asyncTasks Task objects
	 * @return {void}
	 */
	constructor(asyncTasks) {


		/**
		 * Are we alive?
		 *
		 * We immediately stop running tasks when dead.
		 *
		 * @type {Boolean}
		 */
		this.alive = false;

		/**
		 * Are we currently running tasks?
		 *
		 * @type {Boolean}
		 */
		this.running = false;

		/**
		 * Registered tasks
		 *
		 * @type {Array<Object>} Task objects
		 */

		this.tasks = StaticTasks.concat(asyncTasks);

		/**
		 * Determine the update interval
		 *
		 * @type {Integer} (s)
		 */
		this.interval = Math.min(...this.tasks.map(t => t.frequency));

		/**
		 * Interval ID for the update interval timer
		 *
		 * @type {Integer|null}
		 */
		this.intervalId = null;

		/**
		 * Log the last time that task IDs succeeded
		 * 
		 * @type {Object} ID => last successful timestamp
		 */
		this.log = {};

	}


	/**
	 * Realign all tasks as if they've just successfully executed.
	 *
	 * Intended for situations to prevent tasks running again too 
	 * soon after an equivalent action was initiated outside the 
	 * task system (e.g. if the device was registered, we don't 
	 * want to end up running a task loop straight afterwards).
	 * 
	 * @return {void}
	 */
	realign = () => {

		const now = this.constructor.getCurrentTaskTimestamp();

		this.tasks.forEach(task => {
			this.log[task.id] = now;
		});

	};


	/**
	 * Recalculate the interval (Tasks added/removed, new minimum frequency)
	 * 
	 * @return {void}
	 */
	intervalCalc = () => {

		this.interval = Math.min(...this.tasks.map(t => t.frequency));

	};


	/**
	 * Stop interval updates.
	 * 
	 * @return {void}
	 */
	stop = () => {

		this.alive = false;

		window.removeEventListener("online", this.runAll);

		if (this.intervalId) {
			clearTimeout(this.intervalId);
			this.intervalId = null;
		}

	};


	/**
	 * Start interval updates.
	 *
	 * @param {Boolean} immediate optional Queue a run immediately (`true`)
	 * @return {void}
	 */
	start = (immediate=true) => {

		this.alive = true;
		this.running = false;

		this.intervalId = setInterval(
			this.run,
			(this.interval * 1000)
		);

		window.addEventListener("online", this.runAll);

		if (immediate) {
			this.runAll();
		}

	};


	/**
	 * Run all registered tasks.
	 *
	 * @param {Boolean} all optional Force run all tasks?
	 * @return {void}
	 */
	run = async (all=false) => {

		/**
		 * We don't want to execute task runs concurrently or when dead!
		 */
		if (this.running || !this.alive) {
			return;
		}

		/**
		 * We are running!
		 */
		this.running = true;

		/**
		 * Time of this task run
		 */
		const now = this.constructor.getCurrentTaskTimestamp();

		const taskQueue = all ? this.tasks : this.getDueTasks();

		/**
		 * Iterate all our tasks
		 */
		for (const task of taskQueue) {

			/**
			 * Check we can run this task now
			 */
			const {requireOnline, requireRegistration} = task;

			if (requireRegistration && !Store.getState()?.Registration?.Device?.RegistrationToken) continue;
			else if (requireOnline && !navigator.onLine) continue;
			else if (!this.alive) continue;

			/**
			 * Run the task!
			 */
			try {
				await task.task();
				this.log[task.id] = now;
			}

			/**
			 * The task had an error.
			 */
			catch (e) {

				/**
				 * Errors are OK - the task failed but we still 
				 * want to continue running subsequent tasks!
				 */

			}

		}

		/**
		 * Task run complete.
		 */
		this.running = false;

	};


	/**
	 * Force run all tasks even when not due.
	 * 
	 * @return {void}
	 */
	runAll = () => this.run(true);


	/**
	 * Get the set of task objects that are due to run now.
	 *
	 * @return {Array<Object>} Task objects
	 */
	getDueTasks() {

		const now = this.constructor.getCurrentTaskTimestamp();

		return this.tasks.filter(task => {
			const lastRan = this.log[task.id];
			return (!lastRan || ((now - lastRan) >= task.frequency));
		});

	}


	/**
	 * Get whether we are currently running tasks.
	 *
	 * @return {Boolean}
	 */
	// eslint-disable-next-line class-methods-use-this
	get running() {
		return Store.getState().App.Syncing;
	}


	/**
	 * Set whether we are currently running tasks.
	 *
	 * @param {Boolean} running
	 * @return {void}
	 */
	// eslint-disable-next-line class-methods-use-this
	set running(running) {
		dSyncing(running);
	}


	/**
	 * Get our current timestamp for task management purposes.
	 *
	 * @return {Integer} (s)
	 */
	static getCurrentTaskTimestamp() {
		return Math.floor((Date.now() / 1000));
	}

}

export {Tasker};
export default new Tasker([]);

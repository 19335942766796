import {Flex, IconButton, Paper, String} from "@hps/hops-react";
import {useCallback} from "react";

import withInventory from "Hoc/withInventory.js";
import withRegistration from "Hoc/withRegistration.js";
import InventoryGrid from "Inventory/InventoryGrid.js";

import InventoryGridItemSessionProduct from "./InventoryGridItemSessionProduct.js";
import SessionSelector from "./TicketSessionInventoryBrowserSessionSelector.js";

import BackIcon from "@mui/icons-material/ArrowBack";
import ProductIcon from "@mui/icons-material/Storefront";

import scss from "./TicketSessionInventoryProductBrowser.module.scss";

export default withInventory(withRegistration(props => {

	const {activeProduct, onSelectProduct, products, selectedTicketTypeCounts, Inventory} = props;
	const orgId = props.Registration.Org.Id;


	/**
	 * Render the sessions for the active product.
	 *
	 * @return {ReactNode}
	 */
	const renderActiveProduct = useCallback(() => {

		const sessions = ((Inventory?.Tickets?.Sessions || []).filter(s => (s.Product === activeProduct?.Id)) || []);

		return (
			<SessionSelector
				selectedTicketTypeCounts={selectedTicketTypeCounts}
				sessions={sessions} />
		);

	}, [activeProduct, selectedTicketTypeCounts, Inventory]);


	/**
	 * Render the product grid.
	 *
	 * @return {ReactNode}
	 */
	const renderProducts = useCallback(() => (
		<InventoryGrid>
			{
				products.map((product, key) => (
					<InventoryGridItemSessionProduct
						key={key}
						orgId={orgId}
						onSelect={onSelectProduct}
						product={product} />
				))
			}
		</InventoryGrid>
	), [products, orgId, onSelectProduct]);


	/**
	 * Render!
	 */
	return (
		<Paper>
			<Flex>
				<Flex
					alignItems="center"
					className={scss.toolbar}
					columnar={true}>
					<IconButton
						icon={(!activeProduct ? ProductIcon : BackIcon)}
						onClick={onSelectProduct}
						variant="outlined" />
					<String
						str={(activeProduct?.Name || "Available Products")}
						variant="h6" />
				</Flex>
				{(activeProduct ? renderActiveProduct() : renderProducts())}
			</Flex>
		</Paper>
	);

}));

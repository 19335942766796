const Permissions = {
	P013_VIEW_PUBLISHED_OPERATING_CALENDAR: 13,
	P466_VIEW_RETAIL_SALES_ORDERS: 466,
	P467_EDIT_RETAIL_SALES_ORDERS: 467,
	P512_ORDER_STATIONERY_FULFILMENT: 512,
	P524_RECALL_REFUND_IN_POS: 524,
	P525_QUICK_REFUND_IN_POS: 525,
	P526_CREATE_PROVISIONAL_ORDER_IN_POS: 526,
	P527_CREATE_DEPOSIT_ORDER_IN_POS: 527,
	P528_COLLECT_MOTO_PAYMENT: 528,
	P538_PRICE_ADJUSTMENT_IN_POS: 538
};

export default Permissions;

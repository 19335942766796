import {ButtonBase, SnackbarService} from "@hps/hops-react";
import {DeviceService, DeviceTypes} from "@hps/hops-sdk-js";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import {useState} from "react";

import dRegistration from "Dispatchers/dRegistration.js";
import dReset from "Dispatchers/dReset";
import withRegistration from "Hoc/withRegistration";

/* 
 * Wraps a component to act as an escape hatch and reset state.
 */

const EscapeHatch = props => {

	const token = props.Registration?.Device?.RegistrationToken;

	const [clickCount, setClickCount] = useState(0);

	const handleClick = () => {

		setClickCount(clickCount + 1);

		const remainingClicks = props.requiredClicks - clickCount;

		if (remainingClicks === 0) {

			// Reset Device
			dReset();

			if (props.autoRegistration) {

				SnackbarService.snack("Please wait - attempting to re-register the device.", "warning");

				// Re-register device
				DeviceService.getDeviceRegistration(DeviceTypes.POS, token).then(registration => {

					if (registration) {

						dRegistration(registration);
						SnackbarService.snack("This device has been reset!", "success");
						window.location.reload();

					}
					else {
						SnackbarService.snack("The device was reset, but was unable to re-register.", "error");
					}

				}).catch(e => {
					SnackbarService.snack(e);
				});
			}

			return;

		}
		else if (remainingClicks <= 3) {
			SnackbarService.snack(
				`You are now ${remainingClicks} ${pluralize("step", remainingClicks)} away from resetting this device.`
			);
		}

	};

	return (
		<ButtonBase disabled={((props.requiredClicks - clickCount) < 0)} onClick={handleClick}>
			{props.children}
		</ButtonBase>
	);
};

EscapeHatch.defaultProps = {
	autoRegistration: true,
	requiredClicks: 7,
	snackOnClick: true
};

EscapeHatch.propTypes = {
	autoRegistration: PropTypes.bool,
	requiredClicks: PropTypes.number,
	snackOnClick: PropTypes.bool
};

export default withRegistration(EscapeHatch);

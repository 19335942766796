import {Dialog} from "@hps/hops-react";
import React from "react";

/**
 * Device reset confirmation dialog
 * 
 * @package HOPS
 * @subpackage Settings
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DeviceResetDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				onClose={this.props.onClose}
				onOk={this.props.onReset}
				open={this.props.open}
				title="Reset Device"
				str="Are you sure you want to reset this device?" />
		);
	}

}

export default DeviceResetDialog;

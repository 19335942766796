import {TicketJourneyTypes} from "@hps/hops-sdk-js";
import moment from "moment";
import {connect} from "react-redux";

import dTicketsSelectionMerge from "Dispatchers/dTicketsSelectionMerge.js";
import withRegistration from "Hoc/withRegistration.js";

import withInventory from "./withInventory.js";

const withTickets = Component => connect(({Registration, Tickets}) => ({Registration, Tickets}))(withInventory(withRegistration(
	props => {

		const ticketsSelection = {...props.Tickets.Selection};

		const today = new moment();
		const todayYmd = today.format("YYYY-MM-DD");

		if (!ticketsSelection.Date || (new moment(ticketsSelection.Date) < today)) {
			ticketsSelection.Date = todayYmd;
		}

		ticketsSelection.Class ||= props.Inventory?.Tickets?.Classes?.[0];
		ticketsSelection.FareType ||= props.Inventory?.Tickets?.FareTypes?.[0];
		ticketsSelection.JourneyType ||= props.Inventory?.Tickets?.Travel?.DefaultJourneyType || TicketJourneyTypes.values.Return;

		ticketsSelection.TimingPointA ||= (props.Inventory?.Tickets?.TimingPoints?.find(tp => (tp.Id === props.Registration?.Device?.DefaultTimingPoints?.A)) || props.Inventory?.Tickets?.TimingPoints?.find(tp => tp.DefaultA) || props.Inventory?.Tickets?.TimingPoints?.[0]);
		ticketsSelection.TimingPointB ||= (props.Inventory?.Tickets?.TimingPoints?.find(tp => (tp.Id === props.Registration?.Device?.DefaultTimingPoints?.B)) || props.Inventory?.Tickets?.TimingPoints?.find(tp => tp.DefaultB) || props.Inventory?.Tickets?.TimingPoints?.[((props.Inventory?.Tickets?.TimingPoints?.length || 0) - 1)]);
		ticketsSelection.TimingPointC ||= (props.Inventory?.Tickets?.TimingPoints?.find(tp => (tp.Id === props.Registration?.Device?.DefaultTimingPoints?.C)) || props.Inventory?.Tickets?.TimingPoints?.find(tp => tp.DefaultC) || props.Inventory?.Tickets?.TimingPoints?.[Math.floor((((props.Inventory?.Tickets?.TimingPoints?.length || 0) - 1) / 2))]);

		ticketsSelection.TypeCounts = {};

		for (const type of (props.Inventory?.Tickets?.Types || [])) {
			const selectedCount = props.Tickets.Selection?.TypeCounts?.[type.Id];
			ticketsSelection.TypeCounts[type.Id] = ((selectedCount || (selectedCount === 0)) ? selectedCount : (type.ShowNormalTravel ? (type.DefaultSearchCount || 0) : 0));
		}

		const resetTicketTypeCounts = () => {
			const TypeCounts = {};
			for (const type of (props.Inventory?.Tickets?.Types || [])) {
				if (type.ShowNormalTravel) {
					TypeCounts[type.Id] = (type.DefaultSearchCount || 0);
				}
				else TypeCounts[type.Id] = 0;
			}
			dTicketsSelectionMerge({TypeCounts});
		};

		return (
			<Component
				{...props}
				TicketsSelectionKey={props.Tickets.SelectionKey}

				resetTicketTypeCounts={resetTicketTypeCounts}
				TicketsSelection={ticketsSelection}
				TicketsJourneySelectionKey={props.Tickets.JourneySelectionKey} />
		);

	}
)));

export default withTickets;

import {AsyncStatefulComponent, Button, Flex, SnackbarService, String, TextField} from "@hps/hops-react";
import {DeviceService, DeviceTypes} from "@hps/hops-sdk-js";
import React from "react";

import dRegistration from "Dispatchers/dRegistration";

/**
 * Registration form
 * 
 * The device registration form.
 * 
 * @package HOPS
 * @subpackage Registration
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DeviceRegistrationForm extends AsyncStatefulComponent {

	/**
	 * Input ref
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Registration error code
		 * 
		 * @type {Integer|null}
		 */
		error: null,

		/**
		 * Actively registering now?
		 * 
		 * @type {Boolean}
		 */
		registering: false,

		/**
		 * Device token value
		 *
		 * @type {String|null}
		 */
		token: null

	};


	/**
	 * Component updated.
	 *
	 * We refocus the input when no longer disabled.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevState.registering !== this.state.registering) {
			if (!this.state.registering) {
				this.inputRef?.current?.select?.();
			}
		}
	}


	/**
	 * We're registering.
	 *
	 * @param {Event} e optional
	 * @return {void}
	 */
	handleRegister = e => {

		e?.preventDefault?.();

		this.setState({registering: true});

		DeviceService.getDeviceRegistration(DeviceTypes.POS, this.state.token).then(registration => {

			if (registration) {
				dRegistration(registration);
				// dFeatures(registration.Features);

				SnackbarService.snack("Device registered successfully.", "success");
			}
			else {
				SnackbarService.snack("Unable to register device :(", "error");
			}

		}).catch(e => {

			const status = e?.response?.status;

			switch (status) {
				case 404:
					this.setState({error: status});
					break;
				case 403:
					SnackbarService.snack("Unauthorised - License unavailable.", "error");
					break;
				case 410:
					SnackbarService.snack("Device registration expired/out of use.", "error");
					break;
				default:
					SnackbarService.snack(e);
					break;
			}

		}).finally(() => {
			this.setState({registering: false});
		});

	};


	/**
	 * Token value changed.
	 * 
	 * @param {String|null} token
	 * @return {void}
	 */
	handleTokenChange = token => {
		this.setState({token, error: null});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.handleRegister}>
				<Flex gap={2}>
					<String
						centre={true}
						str="Device Registration" />
					<Flex>
						<TextField
							autoFocus={true}
							disabled={this.state.registering}
							error={(this.state.error === 404)}
							errorText="Unknown/invalid code"
							label="Device Code"
							inputRef={this.inputRef}
							maxLength={250}
							placeholderLabel={true}
							onChange={this.handleTokenChange}
							onEnter={this.handleRegister}
							required={true}
							size="medium"
							type="password"
							value={this.state.token} />
						<Button
							disabled={this.state.registering}
							label="Register"
							size="large"
							type="submit"
							variant="contained" />
					</Flex>
				</Flex>
			</form>
		);
	}

	static defaultProps = {
		deviceType: DeviceTypes.POS
	};

}

export default DeviceRegistrationForm;

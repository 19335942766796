import {Flex, Radio, String} from "@hps/hops-react";
import React from "react";
import {connect} from "react-redux";

import dThemeMode from "Dispatchers/dThemeMode.js";

/**
 * App theme selector
 *
 * @package HOPS
 * @subpackage F12
 * @copyright Heritage Operations Processing Limited
 */
const F12Theme = props => {

	const options = [
		{
			label: "System preference",
			value: "auto"
		},
		{
			label: "Dark",
			value: "dark"
		},
		{
			label: "Light",
			value: "light"
		}
	];

	return (
		<Flex gap={0}>
			<String
				color="textSecondary"
				str="Theme"
				variant="subtitle1" />
			<Radio
				onChange={dThemeMode}
				options={options}
				row={true}
				value={props.Theme.Mode} />
		</Flex>
	);

};

export default connect(({Theme}) => ({Theme}))(F12Theme);

import {Bghost} from "@hps/hops-react";

import Store from "App/Store.js";
import dCacheFares from "Dispatchers/dCacheFares";
import PosDeviceService from "Services/PosDeviceService";

const FaresCacheTask = async () => {

	const token = Store.getState().Registration?.Device?.RegistrationToken;

	/**
	 * Registration task only runs if a DeviceType is configured in HOPSApplication
	 * We might be hosting an app that isn't a device.
	 */

	if (token) {
		try {

			const faresCache = await Bghost.exec(PosDeviceService.getTicketCache());
			dCacheFares(faresCache);

		}
		catch (e) {

			/**
			 * Device token not found (404)
			 *
			 * OR
			 * 
			 * Device token no longer valid (410)
			 *
			 * OR
			 *
			 * Org's PoS license no longer valid (403)
			 */
			if ([403, 404, 410].includes(e?.response?.status)) {

				/*
				 * 				SnackbarService.snack(
				 * 				"The device was unable to cache fares for offline use.",
				 * 				"warning",
				 * 				{
				 * 				persist: true,
				 * 				withCloseButton: true
				 * 				}
				 * 				); 
				 */

			}

		}
	}

};


export default FaresCacheTask;

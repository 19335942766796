import {EmptyStateGate, Flex, TabPanel} from "@hps/hops-react";
import React from "react";

import dCentralAdminTab from "Dispatchers/dCentralAdminTab.js";
import withRegistration from "Hoc/withRegistration.js";
import withUi from "Hoc/withUi.js";

import CentralAdminNoFeaturesError from "./CentralAdminNoFeaturesError.js";
import OrderStationeryFulfilmentView from "./OrderStationeryFulfilment/OrderStationeryFulfilmentView.js";

import scss from "./CentralAdminBrowser.module.scss";


/**
 * Renders all available central admin panels as a tabbed layout
 * 
 * @package HOPS
 * @subpackage CentralAdmin
 * @copyright Heritage Operations Processing Limited
 */
const CentralAdminBrowser = props => {

	const TabPanelFlexProps = {className: scss.root};

	const tabs = [
		{
			label: "Order Stationery Fulfilment ",
			component: OrderStationeryFulfilmentView
		}
	].filter(t => !t.hidden);

	const activeTab = Math.min(Math.max(props.Ui.CentralAdminTab, 0), tabs.length);

	if (!tabs.length) {
		return (
			<Flex px={1} py={1}>
				<EmptyStateGate isEmpty={true} />
			</Flex>
		);
	}


	return (
		<TabPanel
			FlexProps={TabPanelFlexProps}
			gap={1}
			noTabsState={CentralAdminNoFeaturesError}
			onChange={dCentralAdminTab}
			tabs={tabs}
			scrollButtons="auto"
			value={activeTab}
			variant="scrollable" />
	);

};

export default withRegistration(withUi(CentralAdminBrowser));

import {Api, CheckoutBasketItem, CheckoutBasketPayment, OrderableTypes, PosCheckoutService} from "@hps/hops-sdk-js";
import moment from "moment";

import Store from "App/Store.js";
import dOrderSynced from "Dispatchers/dOrderSynced";
import dOrderSyncing from "Dispatchers/dOrderSyncing";

/**
 * Order service
 *
 * Wraps the HOPS Order APIs.
 *
 * @package HOPS
 * @subpackage Services
 * @copyright Heritage Operations Processing Limited
 */
class OrderService {


	/**
	 * Add a comms entry for an order
	 *
	 * @param {Integer} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static addCommsLog(OrderIdentity, UserId, CommsLogEntry) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/commslog`,
			method: "POST",
			data: {
				UserId,
				CommsLogEntry
			}
		}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an order ID (or UUID).
	 *
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static getOrder(OrderIdentity) {
		return Api.call({url: `/api/pos/orders/${OrderIdentity}`}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an item ID (or UUID).
	 *
	 * @param {Integer|String} ItemIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static getOrderByItem(ItemIdentity) {
		return Api.call({url: `/api/pos/orders/items/${ItemIdentity}/order`}).then(({data}) => data);
	}


	/**
	 * Record payments against an order
	 * 
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static recordPayments(OrderIdentity, payments) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/payments`,
			method: "POST",
			data: {
				Payments: payments.map(p => {
					return {
						Uuid: p.Uuid,
						Moto: p.Moto,
						PaymentMethod: p.PaymentMethod?.Id,
						PaymentPed: p.PaymentPed,
						PaymentProcessor: p.PaymentProcessor,
						PaymentProcessorData: CheckoutBasketPayment.getPaymentApiData(p),
						PaymentType: p.PaymentType,
						Value: p.Value
					};
				})
			}
		}).then(({data}) => data);
	}

	/**
	 * Get an array of orders matching a search query
	 *
	 * @param {object} an object with search paramater values
	 * @return {Promise} Resolves with the API's response data
	 */
	static search(params) {
		return Api.call({
			url: `/api/pos/orders/search`,
			method: "GET",
			params
		}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an item ID (or UUID).
	 *
	 * @param {Integer|String} ItemIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static sendEmailConfirmation(OrderIdentity, BillingEmail = null) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/emailconfirmation`,
			method: "POST",
			data: {BillingEmail}
		}).then(({data}) => data);
	}


	/**
	 * Attempt to sync the queue.
	 * Lifted and shifted from Time Register.
	 *
	 * This should only be attempted if the device is online.
	 *
	 * @return {void}
	 */
	static async sync() {

		// We'll reset the queue state to this later
		const pending = [];

		/*
		 * We do NOT want multiple concurrent syncs!
		 */
		if (this.syncing) return;
		else dOrderSyncing();


		/*
		 * Sync each order
		 * We sync individually to avoid excessive HOPS load
		 */
		for (const order of this.queue) {
			if (!order.RetryCount || order.RetryCount < 10) {
				await PosCheckoutService.checkout(order).catch(() => {
					const retryOrder = {...order, RetryCount: order.RetryCount ? order.RetryCount + 1 : 1};
					pending.push(retryOrder);
				});
			}
			else {
				pending.push(order); // Just re-queue the order and ignore it, warning displayed in upload queue.
			}
		}

		// Report the sync
		dOrderSynced(pending);

	}


	/**
	 * Update customer details for an order
	 *
	 * @param {Integer} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static updateCustomerDetails(OrderIdentity, BillingCustomerDetails, DeliveryCustomerDetails = null) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/customerdetails`,
			method: "POST",
			data: {
				BillingCustomerDetails,
				DeliveryCustomerDetails
			}
		}).then(({data}) => data);
	}


	/**
	 * Refund an item by quantity within an order
	 * (Refund item quantities can only go down)
	 *
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static async refundOrderItemQuantity(ItemIdentity, RefundQuantity) {
		return await Api.call({
			url: `/api/pos/orders/items/${ItemIdentity}/refund`,
			method: "POST",
			data: {
				RefundQuantity
			}
		}).then(({data}) => data);
	}


	/**
	 * Get unbatched stationery print orders.
	 *
	 * @return {void}
	 */
	static async getStationeryFulfilmentBatches() {
		return await Api.call({
			url: `/api/pos/orders/stationery/batches`,
			method: "GET"
		}).then(({data}) => data);
	}


	/**
	 * Get unbatched stationery print orders.
	 *
	 * @return {void}
	 */
	static async createStationeryFulfilmentBatch(Ids, UserId) {
		return await Api.call({
			url: `/api/pos/orders/stationery/batches`,
			method: "POST",
			data: {
				Ids,
				UserId
			}
		}).then(({data}) => data);
	}


	/**
	 * Get unbatched stationery print orders.
	 *
	 * @return {void}
	 */
	static async markStationeryFulfilmentBatchPrinted(Id) {
		return await Api.call({
			url: `/api/pos/orders/stationery/batches/${Id}/printed`,
			method: "POST"
		}).then(({data}) => data);
	}


	/**
	 * Transform the Order data in to something resembling a checkout
	 * outcome, for re-printing the receipt and stationery.
	 */
	static constructCheckoutBasket(Device, Order) {

		const allDiscounts = Order.Items.reduce((discounts, item) => {

			item.Discounts.forEach(discount => {

				discounts.push({
					...discount,
					ItemClaim: item.Uuid,
					DiscountQty: item.Quantity,
					Discount: {
						Amount: discount.DiscountAmount,
						Code: discount.DiscountCode
					}
				});

			});

			return discounts;

		}, []);

		const PurchasedOrderVouchers = [];

		Order.Items.filter(i => i.OrderableType === OrderableTypes.VoucherSale).forEach(item => {
			PurchasedOrderVouchers[item.Uuid] = item.Orderable.Vouchers.map(voucher => ({
				...voucher,
				Balance: voucher.IssuedBalance
			}));
		});

		return {
			Order: Order.Id,
			PosDevice: {
				Id: Device?.Id,
				Name: Device?.Name,
				Offline: false
			},
			OrderTimestamp: new moment(Order.Timestamp).unix(),
			BasketItems: Order.Items.map(item => OrderService.constructCheckoutBasketItem(item)),
			CheckoutData: {
				PurchasedOrderVouchers
			},
			Discounts: allDiscounts,
			Payments: Order.Payments.map(payment => ({
				PaymentType: payment.Type.Id,
				TenderedAmount: payment.PaidAmount
			})),
			PaymentsChangeIsDue: false // Change isn't stored with the order data
		};

	}

	/*
	 * Construct a CheckoutBasketItem from the order data. This is required to
	 * use client side processes such as Ticket and Receipt (re)printing.
	 * 
	 * For some reason items in the recall API has slightly different
	 * property names for some orderables, so we have to do this remapping.
	 * 
	 * For future generations: It would be nice if we could just pass
	 * item.Orderable as CheckoutBasketItem.Item without having to do
	 * this transformation.
	 */
	static constructCheckoutBasketItem(item) {

		// Temporary fix for API endpoint returning a single discount code as object {...} instead of array [{...}]

		const iDiscounts = Array.isArray(item.Discounts) ? item.Discounts : [item.Discounts["1"]];
		const totalDiscounts = iDiscounts.reduce((a, b) => (a + (b.DiscountAmount || 0)), 0);
		const originalPrice = (item.Price + totalDiscounts);

		// Pre-populate with all the properties of the orderable
		let itemData = {...item.Orderable};

		switch (item.OrderableType) {

			// Products
			case OrderableTypes.Addon:
				itemData = {
					...itemData,
					Name: item.Description
				};
				break;

			case OrderableTypes.Membership:
				itemData = {
					...itemData,
					Description: item.Description
				};
				break;

			// Travel Tickets
			case OrderableTypes.TicketTravel:
				itemData = {
					...itemData,
					Journeys: item.Orderable?.Journeys?.map(journey => ({
						Arrival: journey.ArrivalTrainSchedule,
						Departure: journey.DepartureTrainSchedule,
						Train: journey.Train
					})),
					TicketOption: {
						...item.Orderable.TicketOption,
						Fares: [item.Orderable.TicketOption.Fare]
					}
				};
				break;

			// Session Tickets
			case OrderableTypes.TicketSession:
				itemData = {
					...itemData,
					Session: item.Orderable.TicketSession,
					FareId: null,
					FareType: item.Orderable.FareType,
					TicketType: item.Orderable.TicketType
				};
				break;

			// Seat Reservations
			case OrderableTypes.SeatReservation:
				itemData = {
					...itemData,
					Train: item.Orderable.TrainAsset
				};
				break;

			// Shares
			case OrderableTypes.Shares:
				itemData = {
					...itemData,
					ShareTypeLabel: `${item.Description} Shares`
				};
				break;

			// System
			case OrderableTypes.System:
				itemData = {
					...itemData,
					Description: item.Description
				};
				break;

			default:
		}

		// Return a CheckoutBasketItem
		const cbi = CheckoutBasketItem.construct({
			Uuid: item.Uuid,
			Item: itemData,
			OrderableType: item.OrderableType,
			Price: originalPrice,
			Quantity: item.Quantity,
			RelatedItemUuid: item.RelatedItemUuid,
			VatProportion: item.VatProportion,
			VatRate: item.VatRate,
			StationeryTemplateId: item.Orderable?.StationeryTemplateId || null
		});

		return cbi;

	}


	/**
	 * Get the sync queue contents.
	 *
	 * @return {Array}
	 */
	static get queue() {
		return Store.getState().Orders?.UploadQueue;
	}


	/**
	 * Get whether we're currently syncing.
	 *
	 * @return {Boolean}
	 */
	static get syncing() {
		return Store.getState().Orders?.Syncing;
	}


}

export default OrderService;

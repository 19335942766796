import {SnackbarService} from "@hps/hops-react";
import {Banner, Button} from "@hps/hops-react";
import React from "react";

import dUpdateAvailable from "Dispatchers/dUpdateAvailable.js";
import dUpdateInstalled from "Dispatchers/dUpdateInstalled.js";

import scss from "./UpdateAvailableBanner.module.scss";

/**
 * "Update available" banner
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UpdateAvailableBanner extends React.PureComponent {

	/**
	 * Update now.
	 * 
	 * @return {void}
	 */
	// eslint-disable-next-line class-methods-use-this
	handleUpdate = () => {

		if (!navigator.onLine) {
			SnackbarService.snack("You must be online to install updates.", "error");
			return;
		}

		dUpdateInstalled(true);
		dUpdateAvailable(false);
		window.location.reload(true);

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Banner
				AlertProps={this.constructor.AlertProps}
				action={this.renderAction()}
				title="App Update Available"
				str="Please update the app to access new features, improvements, and bug fixes."
				severity="info" />
		);
	}


	/**
	 * Render the action button.
	 *
	 * @return {ReactNode}
	 */
	renderAction() {
		return (
			<Button
				color="inherit"
				label="Update Now"
				onClick={this.handleUpdate}
				size="small"
				variant="outlined" />
		);
	}


	/**
	 * `Banner` `AlertProps`
	 *
	 * @type {Object}
	 */
	static AlertProps = {className: scss.root};

}

export default UpdateAvailableBanner;

import {EmptyStateGate, Flex, Loadable, String} from "@hps/hops-react";
import React, {useCallback, useEffect, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";
import withRegistration from "Hoc/withRegistration.js";
import scss from "Inventory/InventoryBrowserWithToolbar.module.scss";
import StockControlService from "Services/StockControlService";

import StockDeliveryBookIn from "./StockDeliveryBookIn";
import StockDeliveryList from "./StockDeliveryList";


/**
 * Render a view to handle Stock Delivery
 */
const StockDeliveryView = props => {

	const {Registration} = props;

	const deviceStockLocation = Registration?.Device?.StockLocation;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [expectedDeliveries, setExpectedDeliveries] = useState([]);
	const [selectedDelivery, setSelectedDelivery] = useState(null);


	const fetchData = useCallback(async () => {

		setLoading(true);

		await StockControlService.getExpectedDeliveries(deviceStockLocation)
			.then(data => setExpectedDeliveries(data))
			.catch(error => setError(error))
			.finally(() => setLoading(false));

	}, [deviceStockLocation]);


	/**
	 * Fetch expected deliveries data
	 */
	useEffect(() => {

		fetchData();

	}, [deviceStockLocation, fetchData]);


	/**
	 * A deliver was received, we need to re-load our expected delivery data
	 */
	const handleDeliveryReceived = () => {

		setSelectedDelivery(null);
		fetchData();

	};


	return (
		<Loadable
			error={!!error}
			errorStr={error}
			loading={loading}>
			<EmptyStateGate emptyMessage="No deliveries expected." isEmpty={!expectedDeliveries?.length}>
				<Flex
					className={props.isMobile ? scss.containerReduced : scss.root}
					columnar={!props.isMobile}
					width="100%">

					<StockDeliveryList
						expectedDeliveries={expectedDeliveries}
						onDeliveryClick={setSelectedDelivery}
						selectedDelivery={selectedDelivery} />

					{selectedDelivery ?
						<StockDeliveryBookIn delivery={selectedDelivery} onDeliveryReceived={handleDeliveryReceived} /> :
						<Flex>
							<String color="textSecondary" str="Please choose a delivery to book in" />
						</Flex>
					}
				</Flex>
			</EmptyStateGate>
		</Loadable>
	);

};

export default withAuthUser(withRegistration(StockDeliveryView));

import {PaymentTypes} from "@hps/hops-sdk-js";
import moment from "moment/moment";
import {combineReducers} from "redux";
import {v4 as uuid} from "uuid";

import State from "./State.js";

const offlineBasketLifetimeMinutes = 15;

/**
 * Static Reducers which are always available in every app.
 * They are combined with the async reducer(s) in the hosted app.
 */
const staticReducers = {

	// Admin User State
	AdminUser(state = State.AdminUser, action = {}) {
		switch (action.type) {
			case "adminUser":
				return action.user;
			default:
				return state;
		}
	},

	// Application status changed.
	App(state = State.App, action = {}) {
		switch (action.type) {
			case "app/apiBaseUri":
				return {...state, ApiBaseUri: action.ApiBaseUri};
			case "app/features":
				return {...state, Features: action.features};
			case "app/featuresApp":
				return {...state, FeaturesApp: action.featuresApp};
			case "app/featuresAvailable":
				return {...state, FeaturesAvailable: action.featuresAvailable};
			case "app/featuresForced":
				return {...state, FeaturesForced: action.featuresForced};
			case "app/heartbeatError":
				return {...state, LastHeartbeatErrorTime: action.HeartbeatErrorTime};
			case "app/heartbeatSuccess":
				return {...state, LastHeartbeatSuccessTime: action.HeartbeatSuccessTime};
			case "app/heartbeatTry":
				return {...state, LastHeartbeatTryTime: action.HeartbeatTryTime};
			case "app/syncing":
				return {...state, Syncing: action.Syncing};
			case "app/updateAvailable":
				return {...state, UpdateAvailable: action.UpdateAvailable};
			case "app/updateInstalled":
				return {...state, UpdateInstalled: action.UpdateInstalled};
			default:
				return state;
		}
	},

	// Authenticated User State
	AuthUser(state = State.AuthUser, action = {}) {
		switch (action.type) {
			case "authUser":
				return action.user;
			default:
				return state;
		}
	},

	// Basket State
	Basket(state = State.Basket, action = {}) {

		/** Basket is now Basket.Items! */

		switch (action.type) {
			case "basket":
				return {...state, Items: action.Basket};

			case "basketAdd":
				return {...state, Items: [...state.Items, ...action.items]};

			case "basketClaimData":
				return {...state, ClaimData: {Items: action.Items, Result: action.Result}};

			case "basketRemove":
				/* Only used in Offline mode, online mode dispatches "basket" filtered with the UUIDs of items still in the server basket. */
				return {...state, Items: state.Items.filter(i => !action.items.some(x => x.Uuid === i.Uuid))};

			case "basketReset":
				return State.Basket;

			case "basketUpdate": {
				const Items = [...state.Items];
				const index = Items.indexOf(state.Items.find(i => (i.Uuid === action.item)));
				if (index !== -1) Items[index] = {...Items[index], ...action.props};
				return {...state, Items};
			}

			case "basketCustomerDetails":
				return {...state, CustomerDetails: action.BasketCustomerDetails};

			case "basketDiscounts":
				return {...state, Discounts: action.BasketDiscounts};

			case "basketIdentity":
				return {
					...state,
					Identity: {
						...action.BasketIdentity,
						OfflineBasketUuid: null
					}
				};

			case "basketIdentityOffline":
				return {
					...state,
					Identity: {
						Id: null,
						OfflineBasketUuid: state.Identity?.OfflineBasketUuid ?? uuid(), // Preserve any existing basket UUID
						Expiry: Math.floor(moment(new Date()).add(offlineBasketLifetimeMinutes, "m").toDate() / 1000)
					}
				};

			case "basketLoading":
				return {...state, Loading: action.BasketLoading};

			case "basketQuestions":
				return {...state, Questions: action.BasketQuestions};

			case "basketQuestionsAnswers":
				return {...state, QuestionsAnswers: action.BasketQuestionsAnswers};

			case "basketQuestionsAnswersDeclined":
				return {...state, QuestionsAnswersDeclined: action.BasketQuestionsAnswersDeclined};

			case "payments":
				return {...state, Payments: action.Payments};

			case "paymentsAdd":
				return {...state, Payments: [...state.Payments, ...action.items]};

			case "paymentsRemove":
				return {...state, Payments: state.Payments.filter(i => !action.items.includes(i.Uuid))};

			case "paymentsUpdate": {
				const Payments = [...state.Payments];
				const index = Payments.indexOf(state.Payments.find(i => (i.Uuid === action.item)));
				if (index !== -1) Payments[index] = {...Payments[index], ...action.props};
				return {...state, Payments};
			}

			case "paymentType":
				return {...state, PaymentType: action.PaymentType};

			case "paymentsInProgress":
				return {...state, PaymentsInProgress: action.PaymentsInProgress};

			case "basketGoOffline":
			{
				return {
					...state,
					Identity: {
						Id: null,
						OfflineBasketUuid: state.Identity?.OfflineBasketUuid ?? uuid(),
						Expiry: Math.floor(moment(new Date()).add(offlineBasketLifetimeMinutes, "m").toDate() / 1000)
					},
					Discounts: [], // Discounts don't work offline
					Payments: state.Payments.filter(i => i.PaymentType !== PaymentTypes.Voucher,) // Vouchers can't be verified offline
				};
			}
			default:
				return state;
		}
	},

	// Cache (Fairs)
	Cache(state = State.Cache, action = {}) {
		switch (action.type) {
			case "cacheFares":
				return {
					...state,
					Fares: action.Fares
				};

			default:
				return state;
		}
	},

	// Device State
	Device(state = State.Device, action = {}) {
		switch (action.type) {
			case "device":
				// Spread and merge the existing device settings with any supplied device settings
				return {...state, ...action.Device};
			default:
				return state;
		}
	},

	// Gateway State
	Gateway(state = State.Gateway, action = {}) {
		switch (action.type) {
			case "gateway":
				return action.Gateway;
			default:
				return state;
		}
	},

	// Orders History
	Orders(state = State.Orders, action = {}) {
		switch (action.type) {
			case "orders/addHistory": {

				const midnight = new Date();
				midnight.setHours(0, 0, 0, 0);

				return {
					...state,
					History: [
						action.order, // Add current order to front of history
						...state.History // Filter existing orders, since midnight
							.filter(order => order.OrderTimestamp >= (midnight / 1000))
							.sort((a, b) => b.Order - a.Order)
					]
				};
			}
			case "orders/queue":
				return {...state, UploadQueue: state.UploadQueue.concat([action.order])};
			case "orders/syncing":
				return {...state, Syncing: true};
			case "orders/synced": {

				const time = Math.floor((Date.now() / 1000));
				const updateTime = ((action.pending.length === 0) && (state.UploadQueue.length > 0));

				return {
					...state,
					Syncing: false,
					UploadQueue: action.pending,
					SyncTime: (updateTime ? time : state.SyncTime)
				};

			}
			default:
				return state;
		}
	},

	// Registration data changed.
	Registration(state = State.Registration, action = {}) {
		switch (action.type) {
			case "registration":
				return action.registration;
			default:
				return state;
		}
	},

	// Settings Changed
	Settings(state = State.Settings, action = {}) {
		switch (action.type) {
			case "settings":
				return action.Settings;
			default:
				return state;
		}
	},

	StockControl(state = State.StockControl, action = {}) {
		switch (action.type) {
			case "stockControl/setDelivery": {

				const deliveries = state.Deliveries;
				deliveries[action.key] = action.delivery;

				return {
					...state,
					Deliveries: deliveries
				};

			}

			default:
				return state;
		}
	},

	// Theme status changed.
	Theme(state = State.Theme, action = {}) {
		switch (action.type) {
			case "theme":
				return action.Theme;
			case "theme/accentColour":
				return {...state, AccentColour: action.AccentColour};
			case "theme/accentColourSecondary":
				return {...state, AccentColourSecondary: action.AccentColourSecondary};
			case "theme/fontFamily":
				return {...state, FontFamily: action.FontFamily};
			case "theme/mode":
				return {...state, Mode: action.Mode};
			default:
				return state;
		}
	},

	// Tickets Selection State
	Tickets(state = State.Tickets, action = {}) {
		switch (action.type) {
			case "ticketsSelection":
				return {
					...state,
					Selection: action.TicketsSelection,
					SelectionKey: (state.SelectionKey + 1)
				};

			case "ticketsSelectionMerge":
				return {
					...state,
					Selection: {...state.Selection, ...action.TicketsSelection},
					SelectionKey: (state.SelectionKey + 1)
				};

			case "ticketsJourneySelectionKeyIncrement":
				return {
					...state,
					SelectionKey: state.SelectionKey + 1
				};

			default:
				return state;
		}
	},

	// User Interface State
	Ui(state = State.Ui, action = {}) {

		switch (action.type) {
			case "ui/centralAdminTab":
				return {...state, CentralAdminTab: action.CentralAdminTab};
			case "ui/customerServicesTab":
				return {...state, CustomerServicesTab: action.CustomerServicesTab};
			case "ui/inventoryTab":
				return {...state, InventoryTab: action.InventoryTab};
			case "ui/logoutDialog":
				return {...state, LogoutDialog: action.LogoutDialog};
			case "ui/onlineApp":
				return {...state, OnlineApp: action.OnlineApp};
			case "ui/onlineNetwork":
				return {...state, OnlineNetwork: action.OnlineNetwork};
			case "ui/questionsDialog":
				return {...state, QuestionsDialog: action.QuestionsDialog};
			case "ui/refund":
				return {...state, Refund: action.Refund};
			case "ui/seatReservationDialog":
				return {...state, SeatReservationDialog: action.SeatReservationDialog};
			case "ui/stockTab":
				return {...state, StockTab: action.StockTab};
			case "ui/supervisorLoginDialog":
				return {...state, SupervisorLoginDialog: action.SupervisorLoginDialog};
			case "ui/stationeryPrinting":
				return {...state, StationeryPrinting: action.StationeryPrinting};
			default:
				return state;
		}
	}

};

export const createReducer = (asyncReducers = {}) => {

	// Combine our static and async routers together
	const combinedReducer = combineReducers({
		...asyncReducers,
		...staticReducers
	});

	// Add a root reducer to handle state resets
	const rootReducer = (state, action) => {

		/**
		 * Reset state (In redux undefined === use preloadedState specified in reducer)
		 * All reducers must handle a default case, which is the initial/default state.
		 * (But they should be doing that anyway)
		 * 
		 * We need to keep any Device state because it holds our config for registration.
		 * 
		 */
		const {Device} = state;
		if (action.type === "reset") state = {Device};

		// Or, let one of the app reducers handle this action
		return combinedReducer(state, action);
	};

	return rootReducer;

};

export default createReducer;

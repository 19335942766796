import {History, HOPSApplication, UnhandledErrorView} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";
import React from "react";
import {Provider as ReduxProvider} from "react-redux";
import {Router} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";

import {Detector} from "Components/DetectOffline";
import EscapeHatch from "Components/EscapeHatch";
import ConnectivityBanner from "Ui/Connectivity/ConnectivityBanner";

import Main from "./Main";
import {Store, StorePersistor} from "./Store.js";

/**
 * App entrypoint
 *
 * Renders the root-level application component.
 *
 * @package HOPS
 * @subpackage App
 * @copyright Heritage Operations Processing Limited
 */
const App = props => {

	return (
		<Sentry.ErrorBoundary>
			<Detector polling={{enabled: true, url: `${Api.baseURL}/api/pos/devices/heartbeat`}} />
			<Router history={History}>
				<ReduxProvider store={Store}>
					<PersistGate loading={null} persistor={StorePersistor}>
						<HOPSApplication>
							<Sentry.ErrorBoundary fallback={<UnhandledErrorView EscapeHatch={EscapeHatch} {...props} />}>
								<Main />
							</Sentry.ErrorBoundary>
							<ConnectivityBanner />
						</HOPSApplication>
					</PersistGate>
				</ReduxProvider>
			</Router>
		</Sentry.ErrorBoundary>
	);

};

export default App;

import {Flex, String} from "@hps/hops-react";
import React from "react";

import EscapeHatch from "Components/EscapeHatch.js";
import dRegistration from "Dispatchers/dRegistration";
import dReset from "Dispatchers/dReset";
import DeviceRegistrationView from "Views/Device/DeviceRegistrationView";

import scss from "./RegistrationView.module.scss";

/**
 * Registration view
 *
 * Adds a header to the hops-react <DeviceRegistrationView />
 * 
 * @package HOPS
 * @subpackage Registration
 * @copyright Heritage Operations Processing Limited
 */
const RegistrationView = () => {

	return (
		<DeviceRegistrationView>
			<Flex alignItems="center" className={scss.header} gap={2}>
				<EscapeHatch onRegistration={dRegistration} onReset={dReset} >
					<img
						alt="HOPS"
						className={scss.hopsLogoImg}
						src="/icon.png" />
				</EscapeHatch>
				<String
					bold={true}
					centre={true}
					component="h1"
					str="HOPS Point of Sale"
					variant="h5" />
			</Flex>
		</DeviceRegistrationView>
	);

};

export default RegistrationView;

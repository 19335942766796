import {Dialog, SnackbarService, String} from "@hps/hops-react";
import {useCallback, useState} from "react";

import OrderService from "Services/OrderService";

const OrderStationeryFulfilmentBatchMarkPrintedDialog = props => {

	const {
		BatchId,
		onClose,
		onSuccess,
		open
	} = props;

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		await OrderService.markStationeryFulfilmentBatchPrinted(BatchId)
			.catch(e => SnackbarService.snack(e))
			.then(data => {
				if (data) {
					SnackbarService.snack("Batch marked complete", "success");
					onSuccess?.();
				}
			})
			.finally(() => setIsSubmitting(false));

	}, [BatchId, onSuccess]);


	return (
		<Dialog
			disabled={isSubmitting}
			loading={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			title={`Mark as Printed`}>
			<String str="Are you sure you want to mark this batch as printed?" />
		</Dialog>
	);

};

export default OrderStationeryFulfilmentBatchMarkPrintedDialog;

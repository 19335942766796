import {Loadable, useData} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {memo, useCallback, useMemo} from "react";

import withRegistration from "Hoc/withRegistration.js";

import StripeElementsContextConsumer from "./StripeElementsContextConsumer.js";


/**
 * Org-level Stripe Elements provider for Stripe Connect support
 * 
 * Configures a Stripe Elements instance to charge a given payment 
 * amount using the given org's Stripe Connect account.
 */
const OrgStripeProvider = memo(({children, paymentAmount, onUpdateContext, Registration}) => {

	const integrationConfigFetch = useData(
		useCallback(() => {
			return Api.call({
				url: `/api/payments/stripe/orgs/${Registration.Org.Id}/integration`,
				params: {

					/**
					 * We are only going to work when we're the default
					 * payment processor for the org for the time being, 
					 * as we don't want to support multiple org processors.
					 * 
					 * (If we are not the default card processor, then we 
					 * are not in use, and Stripe functionalities should 
					 * be kept disabled.)
					 */
					checkDefaultCardPaymentMethod: true

				}
			}).then(({data}) => data);
		}, [Registration.Org.Id])
	);

	const stripePromise = useMemo(() => {
		if (integrationConfigFetch.data) {
			return loadStripe(
				integrationConfigFetch.data.ClientApiKey,
				{
					stripeAccount: integrationConfigFetch.data.ConnectAccountId
				}
			);
		}
		else return null;
	}, [integrationConfigFetch.data]);

	if (!paymentAmount || (paymentAmount < 0)) {
		return children;
	}

	return (
		<Elements
			stripe={stripePromise}
			options={{
				mode: "payment",
				amount: paymentAmount,
				currency: "gbp",
				paymentMethodCreation: "manual"
			}}>
			<Loadable
				error={(integrationConfigFetch.error || !integrationConfigFetch.data?.IsDefaultCardPaymentMethod)}
				errorStr={`Stripe payments are not enabled for your HOPS organisation.\nYou are unable to use this feature at this time.`}
				loading={integrationConfigFetch.loading}>
				{children}
			</Loadable>
			<StripeElementsContextConsumer
				onUpdateContext={onUpdateContext} />
		</Elements>
	);

});

export default withRegistration(OrgStripeProvider);

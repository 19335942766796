import {Button, Checkbox, Div, ErrorReporter, Flex, Loader, SnackbarService, String, Table} from "@hps/hops-react";
import moment from "moment/moment";
import {useCallback, useState} from "react";

import withRegistration from "Hoc/withRegistration.js";
import useGateway from "Hooks/useGateway";
import OrderStatusChip from "Search/OrderDetails/OrderStatusChip";
import StationeryService from "Stationery/StationeryService";
import {getOrderableTypeLabel} from "Utils/OrderableTypesUtils";

import OrderStationeryFulfilmentBatchMarkPrintedDialog from "./OrderStationeryFulfilmentBatchMarkPrintedDialog";


/**
 * Render a tab with batched items, which can be printed
 */
const OrderStationeryFulfilmentBatch = props => {

	const {
		Batch,
		OnFetchNewData,
		Registration
	} = props;

	const gateway = useGateway();

	const [selectedBatchItems, setSelectedBatchItems] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [markPrintedDialog, setMarkPrintedDialog] = useState(false);


	const handleMarkPrintedDialogOpen = () => {
		setMarkPrintedDialog(true);
	};


	const handleMarkPrintedDialogClose = () => {
		setMarkPrintedDialog(false);
	};


	const handleMarkPrintedDialogSuccess = () => {
		setMarkPrintedDialog(false);
		OnFetchNewData?.();
	};


	const handleToggleConfirmCheckbox = (value, key) => {

		/** Add item to array */
		if (value === true && !selectedBatchItems.includes(key)) {
			setSelectedBatchItems([
				...selectedBatchItems,
				key
			]);
		}

		/** Recreate array, filtering out item */
		else if (value === false && selectedBatchItems.includes(key)) {
			setSelectedBatchItems([
				...selectedBatchItems.filter(x => x !== key)
			]);
		}

	};


	const handlePrintStationery = useCallback(async () => {

		setIsSubmitting(true);

		// Will we be printing our stationery?
		let willPrintStationery = false;

		// Check Gateway connectivity to determine whether to print stationery
		try {
			willPrintStationery = await gateway.checkConnectivity();
		}
		catch (e) {
			ErrorReporter.report(e);
		}


		if (willPrintStationery) {

			const batchPromises = [];

			Batch.OrderPrints.forEach(orderData => {

				if (selectedBatchItems.includes(orderData.Order.Id.toString())) {

					const orderPrintItems = orderData.PrintItems.map(item => orderData.Order.Items.find(x => x.Id === item));

					batchPromises.push(StationeryService.printOrderItems(
						orderData.Order,
						{
							Id: Batch.Id,
							ItemCount: orderPrintItems.reduce((sum, i) => sum + i.Quantity, 0),
							Org: orderData.Order.Org,
							Timestamp: Batch.Timestamp
						},
						orderPrintItems,
						"Re-printed by HOPS HQ",
						Registration.Stationery,
						Registration.Device
					));
				}

			});

			await Promise.all(batchPromises).then(() => SnackbarService.snack("Completed printing selected items.", "success"));

		}
		else {
			SnackbarService.snack("Connectivity error (check that the Gateway server is running).", "error");
		}

		setIsSubmitting(false);


	}, [Batch, gateway, Registration, selectedBatchItems]);


	const handlePrintCoverLetter = () => {

		const orderPrintItems = `&orders[]=${selectedBatchItems.reduce((str, item) => `${str}&orders[]=${item}`)}`;
		window.open(`https://www.hops.org.uk/order_stationery_print_pdf.php?real_pdf=true&b=${Batch?.Id}${orderPrintItems}`, "_blank");

	};


	const tableFields = [
		{
			render: orderPrint => <Checkbox
				checked={selectedBatchItems.includes(orderPrint.Order.Id.toString())}
				disabled={isSubmitting}
				name={orderPrint.Order.Id.toString()}
				onChange={handleToggleConfirmCheckbox} />
		},
		{
			label: "Order No",
			render: orderPrint => <Flex
				alignItems="center"
				columnar={true}
				gap={0.5}>
				<OrderStatusChip Id={orderPrint.Order.Id} Status={orderPrint.Order.Status} />
				<String str={`at ${(orderPrint.Order.Org.NameTrade || orderPrint.Order.Org.Name)}`} />
			</Flex>
		},
		{
			label: "Order Time",
			render: orderPrint => (new moment(orderPrint.Order.Timestamp)).format("HH:mm DD/MM/YYYY")
		},
		{
			label: "Billing Contact",
			render(orderPrint) {

				const addressBlock = [];
				addressBlock.push(orderPrint.Order.BillingContact?.Address?.Address);
				addressBlock.push(orderPrint.Order.BillingContact?.Address?.City);
				addressBlock.push(orderPrint.Order.BillingContact?.Address?.County);
				addressBlock.push(orderPrint.Order.BillingContact?.Address?.Postcode);
				addressBlock.push(orderPrint.Order.BillingContact?.Address?.Country);

				return (
					<Flex gap={0}>
						{orderPrint.Order.BillingContact && <String str={`${orderPrint.Order.BillingContact.Fname} ${orderPrint.Order.BillingContact.Sname}`} />}
						<Div pb={1}><String str={`${addressBlock.filter(b => b).join("\n")}`} style={{marginBottom: 1}} noFlex={true} /></Div>
						<String str={`Tel: ${(orderPrint.Order.BillingContact?.Phone ?? "(None)")}`} />
						<String str={`Email: ${(orderPrint.Order.BillingContact?.Email ?? "(None)")}`} />
					</Flex>
				);
			}
		},
		{
			label: "Delivery Contact",
			render(orderPrint) {

				const addressBlock = [];
				addressBlock.push(orderPrint.Order.DeliveryContact?.Address?.Address);
				addressBlock.push(orderPrint.Order.DeliveryContact?.Address?.City);
				addressBlock.push(orderPrint.Order.DeliveryContact?.Address?.County);
				addressBlock.push(orderPrint.Order.DeliveryContact?.Address?.Postcode);
				addressBlock.push(orderPrint.Order.DeliveryContact?.Address?.Country);

				return (
					<Flex gap={0}>
						{orderPrint.Order.DeliveryContact && <String str={`${orderPrint.Order.DeliveryContact.Fname} ${orderPrint.Order.DeliveryContact.Sname}`} />}
						<Div pb={1}><String str={`${addressBlock.filter(b => b).join("\n")}`} style={{marginBottom: 1}} noFlex={true} /></Div>
						<String str={`Tel: ${(orderPrint.Order.DeliveryContact?.Phone ?? "(None)")}`} />
						<String str={`Email: ${(orderPrint.Order.DeliveryContact?.Email ?? "(None)")}`} />
					</Flex>
				);
			}
		},
		{
			label: "Items",
			render(orderPrint) {

				const PrintItems = orderPrint.Order.Items?.filter(item => orderPrint.PrintItems.includes(item.Id));

				return (
					<Flex>
						{PrintItems.map((item, key) => <Flex key={key} gap={0}>
							<String bold={true} str={`${item.Quantity - item.QuantityRemoved}x ${getOrderableTypeLabel(item.OrderableType)}`} />
							<String noFlex={true} str={item.Description} />
						</Flex>)}
					</Flex>
				);
			}
		}
	];


	return (
		<Flex>
			<Table
				items={Batch?.OrderPrints}
				fields={tableFields} />

			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="space-between">
				<Flex
					alignItems="center"
					columnar={true}
					justifyContent="flex-start">
					<Button
						disabled={selectedBatchItems.length === 0 || isSubmitting}
						label="Print Stationery"
						onClick={handlePrintStationery}
						variant="contained" />
					<Button
						disabled={selectedBatchItems.length === 0 || isSubmitting}
						label="Print Letter"
						onClick={handlePrintCoverLetter}
						variant="contained" />
					{isSubmitting && <Loader size={20} />}
				</Flex>
				<Flex
					alignItems="center"
					columnar={true}
					justifyContent="flex-end">
					<String
						str="When everything has been successfully printed, mark this batch as printed:"
						variant="body2" />
					<Button
						disabled={isSubmitting}
						label="Mark as Printed"
						onClick={handleMarkPrintedDialogOpen} />

					<OrderStationeryFulfilmentBatchMarkPrintedDialog
						BatchId={Batch?.Id}
						open={markPrintedDialog}
						onClose={handleMarkPrintedDialogClose}
						onSuccess={handleMarkPrintedDialogSuccess} />
				</Flex>
			</Flex>
		</Flex>
	);

};

export default withRegistration(OrderStationeryFulfilmentBatch);

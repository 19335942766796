import {Appv, Flex, Link, Paper, String} from "@hps/hops-react";
import React from "react";

import DeviceRegistrationForm from "./DeviceRegistrationForm.js";

import scss from "./DeviceRegistrationView.module.scss";

/**
 * Onboarding view
 *
 * Base view used by onboarding screens.
 * 
 * @package HOPS
 * @subpackage Views
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */
class DeviceRegistrationView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex alignItems="center" gap={1} px={1} py={1}>
				<Paper alignItems="center" className={scss.root} gap={2} px={2} py={2} minWidth="36rem">
					{this.props.children}
					<DeviceRegistrationForm onRegistration={this.props.onRegistration} />
				</Paper>
				{(!this.props.registered ? this.constructor.renderAppVersion() : this.constructor.renderDeviceSettingsLink())}
			</Flex>
		);
	}


	/**
	 * Render the application version string.
	 * 
	 * @return {ReactNode}
	 */
	static renderAppVersion() {
		return (
			<String
				color="textSecondary"
				str={Appv} />
		);
	}


	/**
	 * Render the device settings link.
	 * 
	 * @return {ReactNode}
	 */
	static renderDeviceSettingsLink() {
		return (
			<Link
				color="textSecondary"
				label={Appv}
				uri="/device" />
		);
	}

}

export default DeviceRegistrationView;

// import dFeaturesAvailable from "Dispatchers/dFeaturesAvailable.js";
import {Flex, Navigator, SnackbarService, UnhandledErrorView} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";
import React from "react";

import BasketClaimErrorDialog from "Basket/BasketClaimErrorDialog.js";
import BasketClaimWarningDialog from "Basket/BasketClaimWarningDialog.js";
import BasketExpirationDialog from "Basket/BasketExpirationDialog.js";
import CheckoutQuestionsDialog from "Checkout/Questions/CheckoutQuestionsDialog.js";
import dDevice from "Dispatchers/dDevice.js";
import dHeartbeatError from "Dispatchers/dHeartbeatError.js";
import dHeartbeatSuccess from "Dispatchers/dHeartbeatSuccess.js";
import dHeartbeatTry from "Dispatchers/dHeartbeatTry.js";
import dUpdateInstalled from "Dispatchers/dUpdateInstalled.js";
import withAuthUser from "Hoc/withAuthUser.js";
import withRegistration from "Hoc/withRegistration";
import LogoutDialog from "Login/LogoutDialog.js";
import SupervisorLoginDialog from "Login/SupervisorLoginDialog.js";
import BasketSeatReservationDialog from "SeatReservation/BasketSeatReservationDialog.js";
import F12 from "Ui/F12/F12.js";
import UiBar from "Ui/UiBar.js";
import UpdateAvailableBanner from "Ui/Updates/UpdateAvailableBanner.js";
import Tasker from "Utils/Tasker.js";

import Router from "./Router.js";

import withApp from "../Hoc/withApp.js";


/**
 * Main application component
 *
 * Handles Store-aware application-level activities and handles tasks.
 *
 * @package HOPS
 * @subpackage App
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */
class Main extends React.PureComponent {


	/**
	 * Component mounted.
	 * We're inside the <PersistGate> here so the store is ready and hydrated.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		dDevice({Type: this.props.deviceType});
		// dFeaturesAvailable(this.props.features);

		/**
		 * Development override for HOPS API base URL
		 */
		const configuredApiBaseUri = this.props.App?.ApiBaseUri;

		if (process.env.REACT_APP_HOPS_API_BASE_URL) {
			// Prefer environment variable
			Api.baseURL = process.env.REACT_APP_HOPS_API_BASE_URL;
		}
		else if (configuredApiBaseUri) {
			// Fall back to value configured in F12 tools.
			Api.baseURL = configuredApiBaseUri;
		}

		// Axios interceptor for requests
		Api.getRequestInterceptors = () => {
			return {
				config(config) {

					const requestStart = new Date();

					dHeartbeatTry(new Date());

					config.metadata = {
						...config.metadata,
						requestStart
					};

					return config;
				},
				error(error) {
					return Promise.reject(error);
				}
			};
		};


		// Axios interceptor for responses
		Api.getResponseInterceptors = () => {

			return {
				response(response) {

					const requestEnd = new Date();

					response.config.metadata = {
						...response.config.metadata,
						requestEnd,
						requestDuration: (requestEnd - (response.config?.metadata?.requestStart || 0))
					};

					if (response.config.metadata.requestDuration < 5000) {

						// Successful request, with reasonable latency. Update the LastHeartbeatSucessTime state						
						dHeartbeatSuccess(new Date());

					}

					return response;
				},
				error(error) {

					const requestEnd = new Date();

					error.config.metadata = {
						...error.config.metadata,
						requestEnd,
						requestDuration: (requestEnd - (error.config?.metadata?.requestStart || 0))
					};

					/** 
					 * Error could be a 4xx or 5xx, which means the server is there but
					 * something went wrong. Check if we got no response at all.
					 */
					if (!(error?.response)) {
						dHeartbeatError(new Date());
					}

					return Promise.reject(error);
				}
			};

		};

		// Add any client tasks to the Tasker queue
		if (this.props.tasks) Tasker.tasks = Tasker.tasks.concat(this.props.tasks);

		// Recalculate the Tasker frequency interval
		Tasker.intervalCalc();

		Tasker.start();

		/**
		 * We reloaded after an update
		 */
		if (this.props.App.UpdateInstalled) {
			SnackbarService.snack("App update installed.", "success");
			dUpdateInstalled(false);
		}

	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		/**
		 * Registration status changed
		 */
		if (prevProps.registered !== this.props.registered) {

			/**
			 * Registered = realign tasks to ensure we 
			 * don't needlessly immediately resync data
			 */
			if (this.props.registered) {
				Tasker.realign();
			}

			/**
			 * Unregistered = redirect to homescreen
			 */
			else {
				Navigator.navigate("/");
			}

		}

	}


	/**
	 * Component unmounting.
	 *
	 * We need to stop task scheduling.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		Tasker.stop();
	}

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		return <>
			<F12 />
			{(this.props.authed && this.props.registered && <UiBar />)}
			<Flex px={1} py={1}>
				<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
					<Router />
				</Sentry.ErrorBoundary>
			</Flex>
			<BasketClaimErrorDialog />
			<BasketClaimWarningDialog />
			<BasketExpirationDialog />
			<LogoutDialog />
			<CheckoutQuestionsDialog />
			<BasketSeatReservationDialog />
			{this.props.authed && <SupervisorLoginDialog />}
			{(this.props.App?.UpdateAvailable && <UpdateAvailableBanner />)}
		</>;
	}

}

export default withAuthUser(withRegistration(withApp(Main)));

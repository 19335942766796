import {Button, Container, EmptyStateGate, Flex, GiftCardsRedeemTicketsSessionSelector, Loadable, Paper, SnackbarService, String, withMobile} from "@hps/hops-react";
import {CheckoutBasketItem, GiftCardInstanceService, Localisation, OrderableTypes} from "@hps/hops-sdk-js";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import withBasket from "Hoc/withBasket.js";
import withInventory from "Hoc/withInventory.js";
import withRegistration from "Hoc/withRegistration.js";
import GiftCardLookup from "Inventory/GiftCards/GiftCardLookup.js";
import InventoryGrid from "Inventory/InventoryGrid.js";
import BasketService from "Services/BasketService.js";
import AppOfflineGate from "Ui/Connectivity/AppOfflineGate.js";

import InventoryGridItemGiftCard from "./InventoryGridItemGiftCard.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RedeemIcon from "@mui/icons-material/Redeem";

import scss from "./GiftCardInventoryBrowser.module.scss";

/**
 * Gift Card inventory browser
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const GiftCardInventoryBrowser = props => {

	const inputRef = React.createRef();

	const {BarcodeString} = useParams();

	const [inputValue, setInputValue] = useState(BarcodeString);
	const [giftCardData, setGiftCardData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [addingToBasket, setAddingToBasket] = useState(null);

	/**
	 * Find existing instances of a gift card in the basket.
	 */
	const findGiftCardExistingBasketItems = giftCard => {
		return props.BasketItems.filter(i => {
			const ot = (i.OrderableType === OrderableTypes.GiftCardInventory);
			const id = (i.Item?.Id === giftCard.Id);
			const price = (i.Price === giftCard.Price.Value);
			return (ot && id && price);
		});
	};

	/** Pop the dialog if there's a Barcode on first render */
	useEffect(() => {

		if (BarcodeString) handleLoadGiftCard();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Adding a gift card.
	 */
	const handleLoadGiftCard = e => {

		e?.preventDefault?.();

		/**
		 * Get our code
		 */
		let code = inputValue;

		/**
		 * Make sure the input's valid
		 */
		if (!inputRef?.current?.reportValidity?.() || !code) {
			return;
		}

		/**
		 * Get the gift card code without hyphens etc.
		 */
		code = GiftCardInstanceService.transformGiftCardCodeInputToApiCode(code);

		/**
		 * We're loading
		 */
		setLoading(true);

		/**
		 * Retrieve the voucher's details
		 */
		GiftCardInstanceService.getGiftCardDetails(code).then(giftCard => {

			if (giftCard.Org !== props.Registration?.Org?.Id) {
				handleGiftCardInvalidError();
			}
			else {

				/**
				 * Show redemption UI
				 */
				setGiftCardData(giftCard);
			}

		}).catch(e => {

			if ((e?.response?.status === 404) || (e?.response?.status === 410)) {
				handleGiftCardInvalidError();
			}
			else if (e?.response?.status === 429) {
				handleError("We've detected suspicious activity from your network. Please wait a while.");
			}
			else handleError(e);

		}).finally(() => {
			setLoading(false);
		});

	};


	/**
	 * Handle an error.
	 */
	const handleError = msg => {
		setError(true);
		SnackbarService.snack(msg, "error");
		setTimeout(() => inputRef?.current?.select?.());
	};


	/**
	 * Handle an error where the gift card code's invalid.
	 */
	const handleGiftCardInvalidError = () => {
		handleError("The code you've entered is invalid or has expired.");
	};


	/**
	 * Input value changed.
	 */
	const handleChangeInputValue = value => {
		setInputValue(GiftCardInstanceService.transformGiftCardCodeInput(value, inputValue));
	};


	/**
	 * No valid selections to make
	 */
	const renderNotValidUi = () => {

		return (
			<Paper>
				<Flex
					justifyContent="center"
					columnar={true}>
					<String str={"There are no items available at the moment."} />
				</Flex>
			</Paper>
		);

	};


	const handleClearGiftCardData = () => {
		setGiftCardData(null);
	};


	/**
	 * Redemption UI
	 */
	const renderRedemptionUi = () => {

		const useSingleColumnView = false;

		return (
			<Container
				columnar={!useSingleColumnView}
				columns="36rem auto"
				fullWidth={true}
				gap={(useSingleColumnView ? 1 : 2)}
				singleColumn={useSingleColumnView}>
				<Flex className={props.bp("md") ? scss.sticky : ""}>
					<Paper
						gap={0}>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								color="secondary"
								str="Gift Card"
								variant="h6" />
							<RedeemIcon color="secondary" />
						</Flex>
						<Flex alignItems="center">
							<Flex fullWidth={true} pt={0.5}>
								<String str={GiftCardInstanceService.getGiftCardCodeDisplayString(giftCardData?.Code)} />
								<String str={`Valid until ${Localisation.formatDateTime(giftCardData.ExpiryDate)}`} />
							</Flex>
						</Flex>
					</Paper>
					<Button
						label="Back to Inventory"
						onClick={handleClearGiftCardData}
						startIcon={ArrowBackIcon} />
				</Flex>
				<Flex>
					{!giftCardData.Inventory?.Validity?.Sessions.length && renderNotValidUi()}
					{giftCardData.Inventory?.Validity?.Sessions &&

					<Paper>
						<Flex gap={2}>
							<Flex
								className={scss.header}
								justifyContent="space-between">
								<Flex gap={0.25}>
									<String
										str="Available Sessions"
										variant="h6" />
								</Flex>
							</Flex>
							<GiftCardsRedeemTicketsSessionSelector
								giftCardData={giftCardData}
								itemsBeingAddedToBasket={addingToBasket}
								onAddToBasket={handleAddInstanceToBasket} />
						</Flex>
					</Paper>}
				</Flex>
			</Container>
		);

	};


	/**
	 * Add redeemed item selection to basket
	 */
	const handleAddInstanceToBasket = async (loadingKey, item) => {

		if (addingToBasket?.length) {
			SnackbarService.snack(
				"Please try again once your previous item has finished being added to your basket.",
				"warning"
			);
			return;
		}

		setAddingToBasket([loadingKey]);

		try {

			/**
			 * Add the Gift Card Instance and relate it to the redeemed item
			 */
			const giftCardInstance = CheckoutBasketItem.construct({
				RelatedItemUuid: item.Uuid,
				OrderableType: OrderableTypes.GiftCardInstance,
				Item: {
					...giftCardData
				},
				Price: 0,
				Quantity: 1,
				VatProportion: 0,
				VatRate: 0
			});

			/**
			 * Order is important here!
			 * 
			 * The Gift Card instance must be added to the basket
			 * first so the item can "see" it as a relation when it
			 * is added, and ignore the price check.
			 */
			await BasketService.addItems([giftCardInstance, item]);

		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setAddingToBasket([]);

	};


	/**
	 * Adding to the basket.
	 * 
	 * @return {void}
	 */
	const handleAddInventoryToBasket = async giftCard => {

		setAddingToBasket(giftCard);

		/** 
		 * Explicit decision not to update Qty on existing items and always add a new item
		 * See https://gitlab.heron-web.com/hps/hops/-/work_items/1434
		 */
		try {
			await BasketService.addItems([
				CheckoutBasketItem.construct({
					OrderableType: OrderableTypes.GiftCardInventory,
					Item: giftCard,
					Price: giftCard.Price.Value,
					Quantity: 1,
					VatProportion: giftCard.Price.VatProportion,
					VatRate: giftCard.Price.VatRate,
					StationeryTemplateId: giftCard.Stationery
				})
			]);
		}
		catch (e) {
			// ...
		}

		setAddingToBasket(null);

	};

	const items = props.Inventory?.GiftCards;

	return (
		<EmptyStateGate isEmpty={!items?.length}>
			<Flex overflowY="auto" width="100%">
				<Loadable error={error} loading={loading}>
					{giftCardData ?
						<AppOfflineGate>
							{renderRedemptionUi()}
						</AppOfflineGate> :
						<>
							<AppOfflineGate>
								<GiftCardLookup
									inputRef={inputRef}
									loading={loading}
									onChange={handleChangeInputValue}
									onSubmit={handleLoadGiftCard}
									value={inputValue} />
							</AppOfflineGate>
							<InventoryGrid>
								{
									items?.map((item, key) => {

										const itemsInBasket = findGiftCardExistingBasketItems(item);
										const countInBasket = itemsInBasket.reduce((a, b) => (a + b.Quantity), 0);
										const maxQtyExceeded = ((item.MaxQuantity !== null) && (countInBasket >= item.MaxQuantity));

										return (
											<InventoryGridItemGiftCard
												key={key}
												imageSrc={`/uploads/local/${props.Registration.Org.Id}/giftcards/${item.Id}/${item.Id}`}
												item={item}
												loading={(addingToBasket?.Id === item.Id)}
												onAddToBasket={handleAddInventoryToBasket}
												skuUnavailable={maxQtyExceeded}
												skuUnavailableMessage={`Maximum of ${item.MaxQuantity} Allowed`} />
										);

									})
								}
							</InventoryGrid>

						</>}
				</Loadable>
			</Flex>
		</EmptyStateGate>
	);

};

export default withBasket(withInventory(withMobile(withRegistration(GiftCardInventoryBrowser))));

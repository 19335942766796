import {AsyncStatefulComponent, Button, Div, Flex, IconButton, Link, Loader, Paper, String, TicketJourneyGrid, Ul} from "@hps/hops-react";
import {CheckoutBasketItem, Localisation, OrderableTypes, TicketJourneyTypes, TicketService} from "@hps/hops-sdk-js";
import moment from "moment";
import React from "react";

import withBasketLoading from "Hoc/withBasketLoading.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withRegistration from "Hoc/withRegistration.js";
import withTickets from "Hoc/withTickets.js";

import TicketTravelInventoryResultsAdvanceBanner from "./TicketTravelInventoryResultsAdvanceBanner.js";
import TicketTravelInventoryResultsNoFaresCacheBanner from "./TicketTravelInventoryResultsNoFaresCacheBanner.js";

import TicketCacheInvalid from "@mui/icons-material/RemoveModeratorOutlined";
import TicketCacheValid from "@mui/icons-material/VerifiedUserOutlined";

/**
 * Shows matching tickets for a travel constraint selection
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TicketTravelInventoryResults extends AsyncStatefulComponent {

	/**
	 * Re-usable date/time objects
	 */
	now = new moment();

	currentDateTime = this.now.format("YYYY-MM-DD HH:mm:ss");

	currentDate = this.now.format("YYYY-MM-DD");

	currentTime = this.now.format("HH:mm:ss");

	/**
	 * Currently searching?
	 *
	 * Used in conjunction with `searchAgain`.
	 * 
	 * @type {Boolean}
	 */
	searching = false;

	/**
	 * Flag that makes us search again immediately when results load
	 *
	 * Used when the active search query changes during a search.
	 * 
	 * @type {Boolean}
	 */
	searchAgain = false;

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Journeys/tickets data
		 * 
		 * @type {Object|null}
		 */
		data: null,

		/**
		 * Error?
		 *
		 * @type {Error|null}
		 */
		error: null,

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: true,

		/**
		 * Store the "next" journey selected for journeys
		 *
		 * This is independent of the main `selection` so it can be 
		 * persisted between searches (i.e. automatically reselect the 
		 * last selected return journey for a departure next time that 
		 * departure is surfaced in a search).
		 * 
		 * @type {Object}
		 */
		nextJourneySelectionCache: {},

		/**
		 * Journey selection
		 *
		 * @type {Object}
		 */
		selection: {

			/**
			 * Journey A
			 * 
			 * @type {Object|null}
			 */
			a: null,

			/**
			 * Journey B
			 *
			 * @type {Object|null}
			 */
			b: null,

			/**
			 * Journey C
			 *
			 * @type {Object|null}
			 */
			c: null

		},

		/**
		 * Cached `TicketsSelection` that we're currently rendering
		 *
		 * @type {Object|null}
		 */
		TicketsSelection: null

	};


	/**
	 * Component mounted.
	 *
	 * Load initial results.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();
		this.loadResults();
	}


	/**
	 * Component updated.
	 *
	 * We reload the results when the active tickets selection changed.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		const journeys = this.state.data?.Journeys;
		const ticketsSelection = this.state.TicketsSelection;

		/**
		 * Changing the search query
		 */
		if (prevProps.TicketsSelectionKey !== this.props.TicketsSelectionKey) {
			this.loadResults();
		}

		/**
		 * Resetting the selection
		 */
		if ((prevProps.TicketsJourneySelectionKey !== this.props.TicketsJourneySelectionKey) && journeys && ticketsSelection) {

			const {journeyA, journeyB, journeyC} = this.getInitialJourneySelection(journeys, ticketsSelection, true);

			this.setState({
				nextJourneySelectionCache: {},
				selection: {
					a: journeyA,
					b: journeyB,
					c: journeyC
				}
			});

		}

	}


	/**
	 * Add to basket.
	 *
	 * @return {void}
	 */
	handleAddToBasket = () => {

		/**
		 * `CheckoutBasketItem`-like objects to purchase
		 */
		const items = [];

		/**
		 * Selected class ID
		 */
		const classId = this.state.TicketsSelection.Class.Id;

		/**
		 * Selected fare type ID
		 */
		const fareTypeId = this.state.TicketsSelection.FareType.Id;

		/**
		 * Selected date
		 */
		const date = this.state.TicketsSelection.Date;

		/**
		 * Selected journey type
		 */
		const journey = this.state.TicketsSelection.JourneyType;

		/**
		 * Offline journey type - Offline tickets are always Open (null)
		 */
		let offlineJourney = null;

		switch (this.state.TicketsSelection.JourneyType) {
			case TicketJourneyTypes.values.Single:
				offlineJourney = [null];
				break;
			case TicketJourneyTypes.values.Return:
				offlineJourney = [null, null];
				break;
			case TicketJourneyTypes.values.Circular:
				offlineJourney = [null, null, null];
				break;
			default: // (Ranger)
				offlineJourney = null;
		}

		/**
		 * Selected journeys
		 */
		const journeys = this.selectedJourneys.map(j => {
			const journey = {...j};
			delete journey.Next;
			return journey;
		});

		const tpA = this.state.TicketsSelection.TimingPointA;
		const tpB = ((this.state.TicketsSelection.JourneyType !== TicketJourneyTypes.values.Ranger) ? this.state.TicketsSelection.TimingPointB : null);
		const tpC = ((this.state.TicketsSelection.JourneyType === TicketJourneyTypes.values.Circular) ? this.state.TicketsSelection.TimingPointC : null);

		/**
		 * Determine the IDs of the ticket types we're buying tickets for
		 */
		const types = Object.keys(this.state.TicketsSelection.TypeCounts).filter(t => {
			return (this.state.TicketsSelection.TypeCounts[t] > 0);
		}).map(t => parseInt(t));

		/**
		 * Iterate each type
		 */
		types.forEach(type => {

			/**
			 * We're buying this many tickets of this type
			 */
			const ticketCount = this.state.TicketsSelection.TypeCounts[type];

			/**
			 * Find the ticket option representing this ticket type 
			 * and class combination; this is reliable as the combination 
			 * of all ticket option properties is always unique and all 
			 * others are constrained by the ticket search operation that 
			 * delivered us this set of ticket options
			 */
			const option = this.state.data?.Tickets?.find(to => {
				return ((to.Type.Id === type) && (to.Class.Id === classId));
			});

			/**
			 * Find the fare for this ticket option with the selected type
			 */
			const fare = option?.Fares?.find(f => (f.Type.Id === fareTypeId));

			/**
			 * We want to buy this ticket!
			 */
			if (fare && (ticketCount > 0)) {
				items.push(
					CheckoutBasketItem.construct({
						OrderableType: OrderableTypes.TicketTravel,
						Item: {
							TicketOption: {
								...option,
								TimingPointA: tpA,
								TimingPointB: tpB,
								TimingPointC: tpC
							},
							Date: date,
							FareId: fare.Id,
							Journeys: ((journey !== TicketJourneyTypes.values.Ranger) ? journeys : [])
						},
						ClientItem: {
							TicketOption: option.Id,
							Date: date,
							Fare: fare.Id,
							Journeys: offlineJourney
						},
						Price: fare.Price,
						Quantity: ticketCount,
						VatProportion: fare.VatProportion,
						VatRate: fare.VatRate,
						StationeryTemplateId: option.Stationery
					})
				);
			}

		});

		/**
		 * We have tickets to buy
		 */
		if (items.length) {
			this.props.onAddToBasket(items);
		}

		/**
		 * Accommodate hops#278 - reset passenger numbers after "add to basket"
		 */
		this.props.resetTicketTypeCounts();

	};


	/**
	 * Selection changed.
	 *
	 * @param {mixed} journey
	 * @param {String} field
	 * @return {void}
	 */
	handleSelectionChange = (journey, field) => {

		const selection = {
			...this.state.selection,
			[field]: journey
		};
		const nextJourneySelectionCache = {
			...this.state.nextJourneySelectionCache
		};

		if (field === "a") {

			selection.b = this.matchJourneySelection({
				Journeys: selection.a?.Next,
				TargetJourneyId: nextJourneySelectionCache[selection.a?.Id],
				TargetDepartureTpId: selection.TimingPointB?.Id,
				TargetDepartureTime: this.currentDateTime
			});

			selection.c = this.matchJourneySelection({
				Journeys: selection.b?.Next,
				PreviousLegJourneys: selection.a?.Next,
				TargetJourneyId: nextJourneySelectionCache[selection.b?.Id],
				TargetDepartureTpId: selection.TimingPointC?.Id,
				TargetDepartureTime: this.currentDateTime
			});

		}
		else if (field === "b") {

			selection.c = this.matchJourneySelection({
				Journeys: selection.b?.Next,
				PreviousLegJourneys: selection.a?.Next,
				TargetJourneyId: nextJourneySelectionCache[selection.b?.Id],
				TargetDepartureTpId: selection.TimingPointC?.Id,
				TargetDepartureTime: this.currentDateTime
			});

			nextJourneySelectionCache[selection.a?.Id] = journey?.Id;

		}
		else if (field === "c") {
			nextJourneySelectionCache[selection.b?.Id] = journey?.Id;
		}

		this.setState({selection, nextJourneySelectionCache});

	};


	/**
	 * Load current results.
	 * 
	 * @return {void}
	 */
	loadResults = () => {

		/**
		 * We are already searching but the query has changed
		 *
		 * We want to ignore this search and search again when it's finished.
		 */
		if (this.searching) {
			this.searchAgain = true;
			return;
		}

		/**
		 * Get the tickets selection to query
		 */
		const selection = {...this.props.TicketsSelection};

		/**
		 * Loading data for the first time means we don't currently 
		 * have any tickets selection object in the state - set it now 
		 * as we refer to this in the getters in the fetch callback.
		 *
		 * We don't set it at this point normally so that the current 
		 * data continues to display properly while a new search loads.
		 */
		if (!this.state.TicketsSelection) {
			this.setState({TicketsSelection: selection});
		}

		/**
		 * Query object to send to API
		 */
		const query = {
			...selection,
			/** hops#245 - Only show trains we're surfacing in PoS */
			Pos: true,
			Class: selection.Class?.Id,
			Journey: selection.JourneyType,
			FareType: selection.FareType?.Id,
			TimingPointA: selection.TimingPointA?.Id,
			TimingPointB: ((selection.JourneyType !== TicketJourneyTypes.values.Ranger) ? selection.TimingPointB?.Id : null),
			TimingPointC: ((selection.JourneyType === TicketJourneyTypes.values.Circular) ? selection.TimingPointC?.Id : null),
			Tickets: selection.TypeCounts,
			IncludePastDepartures: true
		};
		delete query.JourneyType;
		delete query.TypeCounts;

		/**
		 * Total number of tickets we want
		 */
		const ticketCount = Object.values((query.Tickets || {})).reduce((a, b) => (a + b), 0);

		/**
		 * No tickets = all type counts set to 0
		 * or
		 * Some setters didn't have any options available
		 *
		 * Don't search as API requires at least one >0 type count and these fields.
		 */
		if (!ticketCount || !selection.Class || !selection.FareType || !selection.TimingPointA) {
			this.setState({
				data: {},
				loading: false,
				TicketsSelection: selection
			});
			this.searching = false;
			this.searchAgain = false;
			return;
		}


		/**
		 * We're searching!
		 */
		this.searching = true;
		this.props.onLoading?.(true);
		this.setState({loading: true, error: null});

		if (!this.props.ReducedFunctionality) {
			this.loadResultsOnline(selection, query);
		}
		else {
			this.loadResultsOffline(selection);
		}

	};


	/**
	 * Load results from tickets API
	 * 
	 * @return {void}
	 */
	loadResultsOnline = (selection, query) => {

		/**
		 * Get matching tickets/journeys now
		 */
		TicketService.searchTrains(this.props.Registration.Org.Id, query).then(data => {

			if (!this.searchAgain) {

				const {journeyA, journeyB, journeyC} = this.getInitialJourneySelection(data.Journeys, selection);

				this.setState({
					data,
					selection: {
						a: journeyA,
						b: journeyB,
						c: journeyC
					},
					TicketsSelection: selection
				});

			}
		}).catch(error => {
			if (!this.searchAgain) {
				this.setState({error, data: null});
			}
		}).finally(() => {

			this.searching = false;

			/**
			 * We want to search again as the query changed during the search
			 */
			if (!this.searchAgain) {
				this.props.onLoading?.(false);
				this.setState({loading: false});
			}
			else this.loadResults();

			this.searchAgain = false;

		});

	};


	/**
	 * Load results from cache
	 * 
	 * @return {void}
	 */
	loadResultsOffline = selection => {

		/**
		 * Get matching tickets/journeys from the cache
		 */

		try {

			if (!this.searchAgain) {

				/**
				 * Build a dummy API response from the fares cache.
				 * Normally all this filtering happens on the backend.
				 * 
				 * The fares renderer does some of this, but not all and
				 * this logic also whittles us down to [] if there are no valid
				 * fares to select.
				 */

				let validTickets = this.props.Cache?.Fares?.Tickets;

				// Data must be valid for today (API includes a ValidDate when it responds)
				if (this.props.Cache?.Fares?.ValidDate === new moment().format("YYYY-MM-DD")) {

					// console.log("looking for tickets", selection, validTickets);

					// Match Journey Type (Single, Return, Circular, Ranger)
					validTickets = validTickets.filter(t => t.Journey === selection.JourneyType);
					// console.log("journeyType", selection.JourneyType, validTickets);

					// Match Timing Point A
					validTickets = validTickets.filter(t => t.TimingPointA === selection.TimingPointA.Id);
					// console.log("TPA", selection.TimingPointA.Id, validTickets);

					// Match Timing Point B, or blank
					validTickets = selection.JourneyType !== TicketJourneyTypes.values.Ranger ? validTickets.filter(t => t.TimingPointB === selection.TimingPointB.Id) : validTickets.filter(t => t.TimingPointB === null);
					// console.log("TPB", this.requiresSelectionB, selection.TimingPointB.Id, validTickets);

					// Match Timing Point C, or blank
					validTickets = selection.JourneyType === TicketJourneyTypes.values.Circular ? validTickets.filter(t => t.TimingPointC === selection.TimingPointC.Id) : validTickets.filter(t => t.TimingPointC === null);
					// console.log("TPC", this.requiresSelectionC, selection.TimingPointC.Id, validTickets);

					// Match Class (Standard, First Class, etc.)
					validTickets = validTickets.filter(t => t.Class.Id === selection.Class.Id);
					// console.log("Class", selection.Class.Id, validTickets);

					// Match Fare Type (Normal, Members, Shareholders, etc.)
					validTickets = validTickets.filter(t => t.Fares.some(f => selection.FareType.Id === f.Type.Id));
					// console.log("FareType", selection.FareType.Id, validTickets);

					// Match Ticket Type (Adult, Child, Senior, etc.)
					const types = Object.keys(selection.TypeCounts).filter(t => {
						return (selection.TypeCounts[t] > 0);
					}).map(type => parseInt(type));

					validTickets = validTickets.filter(t => types.includes(t.Type.Id));
					// console.log("Ticket Type", types, validTickets);

				}

				/**
				 * Work out the subsequent open journeys
				 */
				let next = [];

				switch (this.state.TicketsSelection.JourneyType) {

					case TicketJourneyTypes.values.Return:
						next = [
							{
								Departure: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointB}
								},
								Arrival: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointA}
								},
								Train: {
									Availability: null
								}
							}
						];
						break;

					case TicketJourneyTypes.values.Circular:
						next = [
							{
								Departure: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointB}
								},
								Arrival: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointC}
								},
								Train: {
									Availability: null
								}
							},
							{
								Departure: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointC}
								},
								Arrival: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointA}
								},
								Train: {
									Availability: null
								}
							}
						];
						break;
					default: // (Single, Ranger)
						next = null;
				}

				/**
				 * Work out the outbound arrival
				 */
				let outboundArrival = null;

				switch (this.state.TicketsSelection.JourneyType) {

					case TicketJourneyTypes.values.Ranger:
						outboundArrival = null;
						break;

					default: // (All other journey types)
						outboundArrival = {
							ArrivalTime: "Open",
							DepartureTime: "Open",
							TimingPoint: {...selection.TimingPointB}
						};
				}

				/**
				 * Construct the results data
				 */
				const data = {
					Tickets: validTickets,
					Journeys: validTickets?.length ?
						[
							{
								Id: null,
								Departure: {
									ArrivalTime: "Open",
									DepartureTime: "Open",
									TimingPoint: {...selection.TimingPointA}
								},
								Arrival: outboundArrival,
								Train: {
									Availability: null
								},
								Next: next
							}
						] :
						null
				};

				const {journeyA, journeyB, journeyC} = this.getInitialJourneySelection(data.Journeys, selection);

				this.setState({
					data,
					selection: {
						a: journeyA,
						b: journeyB,
						c: journeyC
					},
					TicketsSelection: selection
				});

			}

		}
		catch (error) {

			if (!this.searchAgain) {
				this.setState({error, data: null});
			}

		}
		finally {


			this.searching = false;

			/**
			 * We want to search again as the query changed during the search
			 */
			if (!this.searchAgain) {
				this.props.onLoading?.(false);
				this.setState({loading: false});
			}
			else this.loadResults();

			this.searchAgain = false;

		}

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const isSearchingFuture = (this.props.TicketsSelection.Date > this.currentDate);
		const hasNoCache = this.props.Offline && !this.props.ticketCacheValid;

		return (
			<Paper height="100%">
				<Flex>
					<Flex
						alignItems="center"
						columnar={true}
						justifyContent="space-between">
						<String
							str="Available Tickets"
							variant="h6" />
						{(this.state.data && this.state.loading && <Loader size={20} />)}
						{this.props.ticketCacheValid ?
							<IconButton
								color="success"
								icon={TicketCacheValid}
								tooltip="You can sell tickets if this device goes offline." /> :
							<IconButton
								color="warning"
								icon={TicketCacheInvalid}
								tooltip="You won't be able to sell tickets if this device goes offline." />}
					</Flex>
					{(hasNoCache && <TicketTravelInventoryResultsNoFaresCacheBanner />)}
					{(isSearchingFuture && <TicketTravelInventoryResultsAdvanceBanner />)}
					{this.renderContent()}
				</Flex>
			</Paper>
		);

	}


	/**
	 * Render the content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		if (this.state.loading && !this.state.data) return this.constructor.renderLoader();
		else if (this.state.error) return this.renderError();
		else if (!this.state.data?.Journeys?.length) return this.renderEmpty();
		else return this.renderMain();
	}


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	renderEmpty() {
		return (
			<String
				centre={true}
				color="textSecondary"
				str={((this.peopleCount === 0) ? "Please add at least one passenger to display results." : "No matching tickets available.")} />
		);
	}


	/**
	 * Render the main content (journey selection).
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {

		const tpA = this.state.selection.a?.Departure;
		const tpB = this.state.selection.b?.Departure;

		return (
			<Flex gap={2}>
				{(this.requiresSelectionA && this.renderJourneyGrid("a", this.state.data.Journeys))}
				{(this.requiresSelectionB && this.renderJourneyGrid("b", this.state.selection.a?.Next, !this.hasJourneyAvailability(this.state.selection.a), ((this.state.selection.a?.Date < this.currentDate) || ((this.state.selection.a?.Date === this.currentDate) && (tpA?.DepartureTime < this.currentTime)))))}
				{(this.requiresSelectionC && this.renderJourneyGrid("c", this.state.selection.b?.Next, !this.hasJourneyAvailability(this.state.selection.b), ((this.state.selection.b?.Date < this.currentDate) || ((this.state.selection.a?.Date === this.currentDate) && (tpB?.DepartureTime < this.currentTime)))))}
				{this.renderFares()}
				{(this.renderAddToCart())}
			</Flex>
		);

	}


	/**
	 * Render the "add to cart" button.
	 * 
	 * @return {ReactNode}
	 */
	renderAddToCart() {

		const availability = Math.min(...this.selectedJourneys.map(this.constructor.getJourneyAvailability));
		const selectedCancelledJourneys = this.selectedJourneys.find(j => j.Train?.ScheduleType === "CAN");

		const noCancelledTrains = this.state.TicketsSelection.JourneyType === TicketJourneyTypes.values.Ranger ?
			true : // Rangers don't care about cancelled trains
			(!selectedCancelledJourneys) || selectedCancelledJourneys?.length <= 0;

		const availableCapacity = (availability >= this.peopleCount);
		const soldOut = (availability <= 0);

		const canAddToBasket = noCancelledTrains && availableCapacity;

		return (
			<Flex alignItems="flex-start" gap={2}>
				<Button
					disabled={(!canAddToBasket || this.props.BasketLoading)}
					label={(!soldOut ? "Add to Basket" : "Sold Out")}
					loading={this.props.addingToBasket}
					onClick={this.handleAddToBasket}
					size="large"
					variant="contained" />
				{((!availableCapacity && !soldOut) && <String color="error" str={`Not enough seats available (${availability} left).`} />)}
				{((!noCancelledTrains && !soldOut) && <String color="error" str={`Cancelled train in selection.`} />)}
			</Flex>
		);

	}


	/**
	 * Render the fares.
	 * 
	 * @return {ReactNode}
	 */
	renderFares() {

		const cls = this.state.TicketsSelection.Class.Id;
		const ft = this.state.TicketsSelection.FareType.Id;

		const types = Object.keys(this.state.TicketsSelection.TypeCounts).filter(t => {
			return (this.state.TicketsSelection.TypeCounts[t] > 0);
		}).map(type => parseInt(type));

		const fares = types.map(type => {
			const to = this.state.data?.Tickets?.find(to => {
				return ((to.Type.Id === type) && (to.Class.Id === cls));
			});
			return {fare: to?.Fares?.find(fare => (fare.Type.Id === ft)), type: to?.Type};
		}).filter(f => f.fare);

		return (
			<Flex mt={(!this.requiresSelectionA ? 0.5 : 0)}>
				<String
					bold={true}
					noFlex={true}
					str="Fare" />
				{(fares.length === 1) ? this.constructor.renderFare(fares[0].fare) : this.constructor.renderFares(fares)}
			</Flex>
		);

	}


	/**
	 * Render a journey grid.
	 *
	 * @param {String} tp Timing point selection reference (`a`/`b`/`c`)
	 * @param {Array} journeys Array of journey objects to render
	 * @param {Boolean} forceAllUnavailable optional `forceAllUnavailable`
	 * @param {Boolean} forceAllPast optional `forceAllPast`
	 * @return {ReactNode}
	 */
	renderJourneyGrid(tp, journeys, forceAllUnavailable=false, forceAllPast=false) {

		const tpo = this.state.TicketsSelection[`TimingPoint${tp.toUpperCase()}`];

		return (
			<TicketJourneyGrid
				forceAllPast={forceAllPast}
				forceAllUnavailable={forceAllUnavailable}
				journeys={journeys}
				label={(this.requiresSelectionB ? `Depart ${tpo.Name}` : undefined)}
				name={tp}
				onSelect={this.handleSelectionChange}
				requiredAvailability={this.peopleCount}
				selectedJourneyId={this.state.selection[tp]?.Id} />
		);

	}


	/**
	 * Render the error state.
	 * 
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<Flex alignItems="center">
				<String
					centre={true}
					color="error"
					gap={0.5}
					str={["Error loading available tickets.", this.state.error]} />
				<Link
					label="Retry"
					onClick={this.loadResults} />
			</Flex>
		);
	}


	/**
	 * Get the initial journeys to select after loading journey results.
	 *
	 * @param {Array<Object>} journeys Available journey objects
	 * @param {Object} selection Tickets search object results are for
	 * @param {Boolean} ignoreCurrentSelection optional Ignore any current selection
	 * @return {Object} `journeyA`/`journeyB`/`journeyC`
	 */
	getInitialJourneySelection(journeys, selection, ignoreCurrentSelection=false) {

		const journeyA = this.matchJourneySelection({
			Journeys: journeys,
			TargetJourneyId: (!ignoreCurrentSelection ? this.state.selection.a?.Id : undefined),
			TargetDepartureTpId: selection.TimingPointA?.Id,
			TargetDepartureTime: this.currentDateTime
		});

		const journeyB = this.matchJourneySelection({
			Journeys: journeyA?.Next,
			PreviousLegJourneys: journeys,
			TargetJourneyId: (!ignoreCurrentSelection ? (this.state.selection.b?.Id || this.state.nextJourneySelectionCache[this.state.selection.a?.Id]) : undefined),
			TargetDepartureTpId: selection.TimingPointB?.Id,
			TargetDepartureTime: this.currentDateTime
		});

		const journeyC = this.matchJourneySelection({
			Journeys: journeyB?.Next,
			PreviousLegJourneys: journeys,
			TargetJourneyId: (!ignoreCurrentSelection ? (this.state.selection.c?.Id || this.state.nextJourneySelectionCache[this.state.selection.b?.Id]) : undefined),
			TargetDepartureTpId: selection.TimingPointC?.Id,
			TargetDepartureTime: this.currentDateTime
		});

		return {journeyA, journeyB, journeyC};

	}


	/**
	 * Match the best-fit journey object from a collection of journeys, 
	 * while trying to favour first a specific journey when it's present, 
	 * and if not an available journey with specific departure constraints.
	 *
	 * @param {Array<Object>} Journeys Journey objects
	 * @param {Integer} TargetJourneyId
	 * @param {Integer} TargetDepartureTpId
	 * @param {String} TargetDepartureTime HH:mm:ss
	 * @return {Object|undefined}
	 */
	matchJourneySelection({Journeys, PreviousLegJourneys, TargetJourneyId, TargetDepartureTpId, TargetDepartureTime}) {

		/**
		 * Check if the prior leg has departures before the current time.
		 * This assumes multi-leg circular journeys take care of themselves in a chain.
		 */
		const previousLegHasAvailableDepartures = PreviousLegJourneys && PreviousLegJourneys?.find?.(j => {
			return (`${j?.Train?.Date} ${j?.Departure?.DepartureTime}` >= this.currentDateTime);
		})?.count > 0;

		return (
			(
				(TargetJourneyId !== undefined) ?
					Journeys?.find?.(j => {
						return (j.Id === TargetJourneyId);
					}) :
					null
			) ||
			(
				// If no departures are available from the previous leg, fall back to the first Journey
				(previousLegHasAvailableDepartures === false) ? Journeys?.[0] : null
			) ||
			Journeys?.find?.(j => {

				const departureDateTime = `${j?.Train?.Date} ${j?.Departure?.DepartureTime}`;

				return (
					this.hasJourneyAvailability(j) &&
					(j?.Departure?.TimingPoint?.Id === TargetDepartureTpId) &&
					(departureDateTime >= TargetDepartureTime)
				);
			}) ||
			Journeys?.[0]
		);
	}


	/**
	 * Get whether a journey has sufficient availability 
	 * to cover our current ticket selection.
	 * 
	 * @param {Object} journey
	 * @return {Boolean}
	 */
	hasJourneyAvailability(journey) {
		return (this.constructor.getJourneyAvailability(journey) >= this.peopleCount);
	}


	/**
	 * Get the number of people travelling.
	 * 
	 * @return {Integer}
	 */
	get peopleCount() {
		return Object.keys(this.state.TicketsSelection.TypeCounts).reduce((current, typeId) => {
			const type = this.props.Inventory?.Tickets?.Types?.find(t => (t.Id === parseInt(typeId)));
			if (!type?.ShowNormalTravel) return current;
			else return (current + ((type?.People || 1) * this.state.TicketsSelection.TypeCounts[typeId]));
		}, 0);
	}


	/**
	 * Get whether we require a leg A journey selection.
	 *
	 * @return {Boolean}
	 */
	get requiresSelectionA() {
		return (this.state.TicketsSelection.JourneyType !== TicketJourneyTypes.values.Ranger);
	}


	/**
	 * Get whether we require a leg B journey selection.
	 *
	 * @return {Boolean}
	 */
	get requiresSelectionB() {
		return [
			TicketJourneyTypes.values.Return,
			TicketJourneyTypes.values.Circular
		].includes(this.state.TicketsSelection.JourneyType);
	}


	/**
	 * Get whether we require a leg C journey selection.
	 * 
	 * @return {Boolean}
	 */
	get requiresSelectionC() {
		return (this.state.TicketsSelection.JourneyType === TicketJourneyTypes.values.Circular);
	}


	/**
	 * Get our selected journey objects.
	 * 
	 * @return {Array}
	 */
	get selectedJourneys() {
		return [
			this.state.selection.a,
			this.state.selection.b,
			this.state.selection.c
		].filter(t => t);
	}


	/**
	 * Render the loader.
	 * 
	 * @return {ReactNode}
	 */
	static renderLoader() {
		return (
			<Flex
				alignItems="center"
				width="100%">
				<Loader size={30} />
			</Flex>
		);
	}


	/**
	 * Render a fare.
	 *
	 * @param {Object} fare
	 * @param {String} typeName Ticket type name to display
	 * @return {void}
	 */
	static renderFare(fare, typeName=null) {
		return (
			<String
				inline={true}
				str={`${Localisation.formatCurrency(fare.Price)}${(typeName ? ` (${typeName})` : "")}`}
				variant="h6" />
		);
	}


	/**
	 * Render a set of fares as a list.
	 *
	 * @param {Array<Object>} fares `fare` (Ticket Fare)/`type` (Ticket Type)
	 * @return {ReactNode}
	 */
	static renderFares(fares) {
		return (
			<Div pl={1}>
				<Ul gap={0.5} verticalAlign="middle">
					{
						fares.map(({fare, type}, key) => (
							<li key={key}>
								{this.renderFare(fare, type?.Name)}
							</li>
						))
					}
				</Ul>
			</Div>
		);
	}


	/**
	 * Get the effective availability of a journey.
	 * 
	 * @param {Object} journey
	 * @return {Integer|Infinity}
	 */
	static getJourneyAvailability(journey) {
		const availability = journey?.Train?.Availability;
		return ((availability !== null) ? availability : Infinity);
	}

}

export default withBasketLoading(withReducedFunctionality(withRegistration(withTickets(TicketTravelInventoryResults))));

import {useMemo} from "react";
import {useSelector} from "react-redux";

/**
 * Hook to utilise the POS Registration
 */
const useRegistration = () => {

	const registration = useSelector(store => store.Registration);

	return useMemo(() => {
		return {
			...registration
		};
	}, [registration]);

};

export default useRegistration;

import withBasket from "Hoc/withBasket";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import withRegistration from "Hoc/withRegistration.js";
import withSettings from "Hoc/withSettings";
import PaymentProcessorHOPSPaymentApp from "Payment/Processors/HOPSPaymentApp/PaymentProcessorHOPSPaymentApp";
import PaymentProcessorNonIntegratedCard from "Payment/Processors/PaymentProcessorNonIntegratedCard";

const PaymentHandlerPlaceholder = props => {

	/*
	 * All PaymentTypes.Placeholder payments come through this gatehouse.
	 * 
	 * Use this component to determine which processor to use for a PaymentType/PaymentMethod.
	 */

	if (props.Registration?.Org?.StripeConnect?.LocationId) {
		return <PaymentProcessorHOPSPaymentApp {...props} />;
	}
	else {
		return <PaymentProcessorNonIntegratedCard {...props} />;
	}
};

export default withBasket(withReducedFunctionality(withRegistration(withSettings(PaymentHandlerPlaceholder))));

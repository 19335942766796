import {Counter, Dialog, Flex, Radio, SnackbarService, String} from "@hps/hops-react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import {useCallback, useState} from "react";

import withRegistration from "Hoc/withRegistration.js";
// import GatewayPrinterPicker from "Gateway/GatewayPrinterPicker";
import StationeryService from "Stationery/StationeryService";

const PrintStationeryDialog = props => {

	const {
		onClose,
		product,
		onSuccess,
		open,
		Registration
	} = props;


	const [printQuantity, setPrintQuantity] = useState(1);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [stationeryTemplateId, setStationeryTemplateId] = useState(6);
	// const [printerId, setPrinterId] = useState(null);
	const [barcode, setBarcode] = useState(null);


	/**
	 * Submitting.
	 */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		setIsSubmitting(true);

		await StationeryService.printOrgProductItems(
			[
				{
					Item: product,
					Quantity: printQuantity,
					StationeryTemplateId: 6,
					Barcode: barcode
				}
			],
			Registration.Stationery,
		)
			.catch(e => SnackbarService.snack(e))
			.then(data => {
				if (data) {
					SnackbarService.snack("Stationery printed", "success");
					onSuccess?.();
				}
			})
			.finally(() => setIsSubmitting(false));


	}, [barcode, onSuccess, printQuantity, product, Registration]);


	const stationeryTemplateOptions = [
		{
			label: "Shelf Edge Label",
			value: 6
		}
	];


	/**
	 * Render!
	 */
	return (
		<Dialog
			okDisabled={!(stationeryTemplateId && barcode && printQuantity)}
			okLabel="Print"
			onClose={onClose}
			onOk={handleSubmit}
			onSuccess={onSuccess}
			loading={isSubmitting}
			open={open}
			title="Print Product Stationery" >
			<form onSubmit={handleSubmit}>

				{/* 				<GatewayPrinterPicker
					name="StationeryPrinterId"
					onChange={setPrinterId}
					value={printerId} /> */}

				<Flex columnar={true} justifyContent="space-between">

					<Flex>
						<Radio
							label="Stationery type"
							onChange={setStationeryTemplateId}
							options={stationeryTemplateOptions}
							value={stationeryTemplateId} />

						<Radio
							label="Barcode"
							onChange={setBarcode}
							options={product.Barcodes.map(barcode => ({
								label: barcode.Barcode,
								value: barcode.Barcode
							}))}
							value={barcode} />

						{product.Barcodes.length === 0 && <String color="error" str="This product has no barcodes" variant="body2" />}
					</Flex>

					<Counter
						label={pluralize("Copy", printQuantity)}
						min={1}
						onChange={setPrintQuantity}
						value={printQuantity} />

				</Flex>

			</form>
		</Dialog>
	);

};

PrintStationeryDialog.propTypes = {
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool
};

export default withRegistration(PrintStationeryDialog);

import {Bghost} from "@hps/hops-react";

import Store from "App/Store.js";

const dReset = () => {
	Bghost.invalidate();
	Store.dispatch({type: "reset"});
};

export default dReset;

import {Flex, Paper, String} from "@hps/hops-react";
import React, {useState} from "react";

import DeviceAdminAuthGateForm from "./DeviceAdminAuthGateForm.js";

/**
 * Admin authentication gate
 *
 * Requires an admin to correctly enter the device's token 
 * as authentication before the component's children is rendered.
 * 
 * @package HOPS
 * @subpackage Components
 * @copyright Heritage Operations Processing Limited
 */
const DeviceAdminAuthGate = props => {

	const [authenticated, setAuthenticated] = useState(false);

	/**
	 * Authenticated.
	 * 
	 * @return {void}
	 */
	const handleAuthenticated = () => {
		setAuthenticated(true);
	};


	if (!authenticated) {
		return (
			<Paper variant="outlined">
				<Flex alignItems="flex-start" gap={1.5}>
					<Flex gap={0.5}>
						<String
							bold={true}
							color="error"
							str="Authentication Required" />
						<String
							color="textSecondary"
							str="Please confirm this device's registration code to continue." />
					</Flex>
					<DeviceAdminAuthGateForm onAuthenticated={handleAuthenticated} />
				</Flex>
			</Paper>
		);
	}
	else return props.children;

};

export default DeviceAdminAuthGate;

import {Banner, Button, Flex, Paper, String} from "@hps/hops-react";

import withBasket from "Hoc/withBasket";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import withRegistration from "Hoc/withRegistration.js";
import withSettings from "Hoc/withSettings";


/**
 * Process a payment made with a Stripe Terminal reader.
 * 
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @param {object} Registration the POS registration to check the organisation is configured for Stripe.
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} Settings the device settings to find which reader is configured.
 * @returns JSX
 */
const RefundProcessorStripeTerminal = ({onPaymentFailure, RequestedAmount}) => {

	/**
	 * User pressed the Cancel button. Our parent component decides what to do.
	 * @returns {Promise}
	 */
	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};


	/**
	 * Render everything
	 */
	return (
		<Flex>
			<String bold={true} str="Stripe Terminal Card Refund" variant="h5" />
			<Paper>
				<Flex alignItems="center">

					<Banner title="Stripe Terminal doesn't support in-person refunds.">
						Use Recall to find a customer's order, then refund the original payment.
					</Banner>

					<Button label="Cancel" onClick={handleCancel} />

				</Flex>
			</Paper>
		</Flex>
	);

};

export default withBasket(withReducedFunctionality(withRegistration(withSettings(RefundProcessorStripeTerminal))));

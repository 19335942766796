import {TextField, useUpdated} from "@hps/hops-react";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import dApiBaseUri from "../../Dispatchers/dApiBaseUri.js";

import Strings from "./F12.strings.json";

/**
 * F12 API URI input
 *
 * @package HOPS
 * @subpackage F12
 * @copyright Heritage Operations Processing Limited
 */
const F12Api = props => {

	/**
	 * Saving?
	 * 
	 * @type {Boolean}
	 */
	const [saving, setSaving] = useState(false);

	/**
	 * Input value
	 *
	 * @type {String}
	 */
	const [value, setValue] = useState("");


	/**
	 * Save a value change.
	 * 
	 * @return {void}
	 */
	const handleSave = () => {
		if (value !== props.App?.ApiBaseUri) {
			props.onSave();
			setSaving(true);
			dApiBaseUri(value);
			setTimeout(() => window.location.reload(), 250);
		}
	};

	/**
	 * Set the current value from props.
	 * 
	 * @return {void}
	 */
	const setFromProps = () => setValue(props.App?.ApiBaseUri);


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	useEffect(() => {
		setFromProps();
	}, []);


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	useUpdated(() => setFromProps(), [props.App?.ApiBaseUri]);


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	return (
		<TextField
			controlled={true}
			disabled={(props.disabled || saving)}
			helperText={Strings.captions.api}
			label={Strings.api}
			onBlur={handleSave}
			onChange={setValue}
			onEnter={handleSave}
			placeholder={Strings.placeholders.default}
			value={value} />
	);

};

export default connect(({App}) => ({App}))(F12Api);

const withDevice = Component => (
	props => {

		return (
			<Component
				{...props}
				apiBaseUrl="/api/pos" />
		);
	}
);

export default withDevice;

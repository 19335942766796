import Store from "App/Store.js";

const dRegistration = registration => {

	// Update Registration
	Store.dispatch({type: "registration", registration});


	const hexColour = "^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
	const hexColourLeadingHash = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

	// Set Accent Colour
	let orgAccentColour = registration.Org?.HopsAccentColour;

	if (orgAccentColour) {

		// Add a leading '#' symbol to a hex colour, if required.
		if (orgAccentColour.match(hexColour) && !orgAccentColour.match(hexColourLeadingHash)) {
			orgAccentColour = `#${orgAccentColour}`;
		}

		// Update Theme state
		Store.dispatch({type: "theme/accentColour", AccentColour: orgAccentColour});
	}

	/*
	 *  These properties are for HOPS Local, and aren't set yet.
	 * const orgAccentColourSecondary = registration.AccentColourSecondary;
	 * const orgFontFamily = registration.FontFamily;
	 * const orgFontFamilyGoogle = registration.FontFamilyGoogle;
	 */

};

export default dRegistration;

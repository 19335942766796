import {Dialog, ErrorReporter, Flex, Hidden, SnackbarService, String, TextField} from "@hps/hops-react";
import React, {useEffect, useState} from "react";

import withRegistration from "Hoc/withRegistration.js";
import useGateway from "Hooks/useGateway";

import StationeryService from "./StationeryService.js";


/**
 * Print order stationery confirmation dialog
 */
const StationeryPrintConfirmationDialog = props => {

	const {
		onPrintingComplete,
		orderData,
		printLines,
		Registration
	} = props;

	const gateway = useGateway();
	const inputRef = React.createRef();

	const [error, setError] = useState(false);
	const [invalidatedReason, setInvalidatedReason] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = invalidatedReason => {
		setInvalidatedReason(invalidatedReason);
		setError(false);
	};


	// Effect to handle re-selecting input on error
	useEffect(() => {
		if (error) inputRef?.current?.select?.();
	}, [error, inputRef]);


	// Hook to handle printLines being updated
	useEffect(() => {
		if (printLines.length) setPrintTicketsConfirmationDialogOpen(true);
	}, [printLines]);


	// Submit the invalidation reason form
	const handleSubmit = e => {

		const willInvalidate = printLines.some(x => x.StationeryPrints?.length);

		e?.preventDefault?.();

		if ((!willInvalidate) || (willInvalidate && invalidatedReason)) handlePrintTicketsConfirmationDialogSubmit(invalidatedReason);
		else setError(true);

	};


	// Show or hide the print tickets confirmation dialog
	const [printTicketsConfirmationDialogOpen, setPrintTicketsConfirmationDialogOpen] = useState(false);


	// Allow closing of the dialog (Submit or Cancel)
	const handlePrintTicketsConfirmationDialogClose = () => setPrintTicketsConfirmationDialogOpen(false);


	// Handle confirmation dialog submit and do the actual printing
	const handlePrintTicketsConfirmationDialogSubmit = async reason => {

		setIsSubmitting(true);

		// Will we be printing our stationery?
		let willPrintStationery = false;

		// Check Gateway connectivity to determine whether to print stationery
		try {
			willPrintStationery = await gateway.checkConnectivity();
		}
		catch (e) {
			ErrorReporter.report(e);
		}


		if (willPrintStationery) {

			await StationeryService.printOrderItems(
				orderData,
				{},
				printLines,
				reason,
				Registration.Stationery,
				Registration.Device
			);

			SnackbarService.snack("Stationery re-printed.", "success");

		}
		else {
			SnackbarService.snack("Connectivity error (check that the Gateway server is running).", "error");
		}

		setIsSubmitting(false);

		// Signal parent that we're done
		onPrintingComplete?.();

		handlePrintTicketsConfirmationDialogClose();
	};


	const formStyles = {width: "100%"};
	const inputProps = {step: 0.01};

	const willInvalidate = printLines.some(x => x.StationeryPrints?.length);

	return (
		<Dialog
			closeLabel={willInvalidate ? undefined : "No"}
			loading={isSubmitting}
			okLabel={willInvalidate ? undefined : "Yes"}
			onClose={handlePrintTicketsConfirmationDialogClose}
			onOk={handleSubmit}
			open={printTicketsConfirmationDialogOpen}
			title="Print Stationery">
			<form
				onSubmit={handleSubmit}
				style={formStyles}>

				<String
					str="Are you sure you want to print this order stationery?" />

				<Hidden hidden={!willInvalidate}>
					<Flex gap={0}>
						<String
							color="error"
							str="This action will invalidate existing stationery barcode(s)." />

						<TextField
							autoFocus={true}
							error={error}
							errorText="Please enter an reason."
							fullWidth={true}
							inputProps={inputProps}
							inputRef={inputRef}
							label="Reason for re-printing stationery:"
							multiline={true}
							onChange={handleChange}
							onEnter={handleSubmit}
							required={willInvalidate}
							rows={3}
							value={invalidatedReason}
							variant="standard" />
					</Flex>
				</Hidden>

			</form>
		</Dialog>
	);

};

export default withRegistration(StationeryPrintConfirmationDialog);

import {String} from "@hps/hops-react";

import Permissions from "App/Permissions";
import withAuthUser from "Hoc/withAuthUser";
import withBasket from "Hoc/withBasket";
import withRegistration from "Hoc/withRegistration.js";
import withSettings from "Hoc/withSettings";
import PaymentProcessorNonIntegratedCard from "Payment/Processors/PaymentProcessorNonIntegratedCard";
import RefundProcessorNonIntegratedCard from "Payment/Processors/RefundProcessorNonIntegratedCard";
import PaymentProcessorStripeMoto from "Payment/Processors/Stripe/PaymentProcessorStripeMoto";

const PaymentHandlerCardMoto = props => {

	/*
	 * All PaymentTypes.Card payments come through this gatehouse.
	 * 
	 * Use this component to determine which processor to use for a PaymentType/PaymentMethod.
	 */

	/**
	 * No Permission
	 */
	if (!props.hasPermission(Permissions.P528_COLLECT_MOTO_PAYMENT)) {
		return <String
			bold={true}
			color="error"
			str={["Error: You don't have permission to take Card Not Present (MOTO) payments.", "P: 528 ", "V: 0"]} />;
	}

	/**
	 * Stripe
	 */
	else if (props.Registration?.Org?.StripeConnect?.AccountId) {

		// Negative payments go through the refund processor
		if (props.PaymentsBalanceDue < 0) return <></>;

		return <PaymentProcessorStripeMoto {...props} />;
	}

	/**
	 * Non-integrated
	 */
	else {

		// Negative payments go through the refund processor
		if (props.PaymentsBalanceDue < 0) return <RefundProcessorNonIntegratedCard {...props} />;

		return <PaymentProcessorNonIntegratedCard {...props} />;
	}
};

export default withAuthUser(withBasket(withRegistration(withSettings(PaymentHandlerCardMoto))));

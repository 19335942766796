import {connect} from "react-redux";

const withRegistration = Component => connect(({Registration}) => ({Registration}))(
	props => (
		<Component
			{...props}
			hasRegistrationFeature={feature => !!props.Registration?.Features?.includes(feature)}
			registered={!!props.Registration?.Device?.RegistrationToken} />
	)
);

export default withRegistration;

import {Button, Div, Divider, Flex, Hidden, String} from "@hps/hops-react";
import React from "react";
import {memo} from "react";

import ReceiptUi from "Receipts/ReceiptUi.js";

import CheckoutOutcomeDetailsTable from "./CheckoutOutcomeDetailsTable.js";

import TicketIcon from "@mui/icons-material/Print";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";

import scss from "./CheckoutViewSuccessStage.module.scss";


/**
 * Checkout view confirmation success stage
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutViewSuccessStage = memo(props => {

	return (
		<Div className={scss.root}>
			<Flex
				alignItems="flex-start"
				columnar={true}
				flexWrap={true}
				gap={2}
				justifyContent="center">
				<Flex>
					<String
						bold={true}
						centre={true}
						str={(!props.PaymentOutcome.wasProvisionalCheckout ? "Transaction Complete" : "Provisional Order Saved")}
						variant="h5" />

					<Button
						className={scss.newSaleButton}
						label="New Sale"
						uri="/"
						size="large"
						endIcon={QueuePlayNextIcon}
						variant="contained" />

					<Divider
						className={scss.divider}
						light={true} />

					<CheckoutOutcomeDetailsTable
						outcome={props.PaymentOutcome} />


					<Hidden hidden={(!props.onPrintStationery || props.PaymentOutcome.wasProvisionalCheckout)}>
						<Button
							disabled={props.isStationeryPrinting}
							label="Print Stationery"
							onClick={props.onPrintStationery}
							size="large"
							startIcon={TicketIcon} />
					</Hidden>

				</Flex>
				{(
					!props.PaymentOutcome.wasProvisionalCheckout &&
					<Flex>
						<String
							bold={true}
							centre={true}
							str="Customer Receipt"
							variant="h5" />
						<ReceiptUi
							KickCashDrawer={true}
							PaymentOutcome={props.PaymentOutcome} />
					</Flex>
				)}
			</Flex>
		</Div>
	);

});

export default CheckoutViewSuccessStage;

import {Navigator, SnackbarService} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes} from "@hps/hops-sdk-js";

import Store from "App/Store.js";
import BasketService from "Services/BasketService.js";

/**
 * Match scanned text with rules, and run any handler.
 * 
 * In future we probably want to fall back to looking up an product barcode.
 * It's not worth making a rule for product barcodes as there are so many
 * variations, and Railways could even print their own arbitrary barcodes
 * with Code 128 and a Zebra label printer.
 *
 * @param {string} input the scanned text
 * @return {void}
 */
const handleScan = async input => {

	/** Check for a Regex Rule */
	const handled = scanRules.some(rule => {
		const match = input.match(rule.exp);
		if (match) rule.handle(match);
		return match;
	});

	if (handled) return;

	/** Fall back to check for a Barcode */
	const barcodeMatches = Store.getState()?.Registration?.Inventory?.Products?.filter(p => p.Barcodes.includes(input));

	/** Single match, add to basket */
	if (barcodeMatches.length === 1) {

		const product = barcodeMatches[0];

		await BasketService.addItems([
			CheckoutBasketItem.construct({
				OrderableType: OrderableTypes.Addon,
				Item: product,
				Price: product.Price,
				Quantity: 1,
				VatProportion: product.VatProportion,
				VatRate: product.VatRate
			})
		]);

		/** Show a snack - we might not be on the OrderView screen to see it appear in the basket */
		SnackbarService.snack(`${product.Name} has been added to the basket.`, "success");
		return;

	}

	/** Multiple matches, show product inventory view */
	else if (barcodeMatches.length > 1) {

		Navigator.navigate(`/inventory/products/${input}`);
		return;

	}

	/** Failed */
	else {

		SnackbarService.snack("The code you entered wasn't recognised.", "error");
		return;

	}
};

/**
 * Regular Expression rules for matching entered text to a handling function.
 * This could be as simple as a redirect, or something more complicated like
 * adding an item to the basket, or applying a discount/voucher.
 * 
 * Try to minimise the expression scope as much as possible so there aren't overlaps.
 * If appropriate, add a case insensitive expression flag at the end (/i)
 */
const scanRules = [
	{
		/*
		 * Retail Ticket Order/Item QR Code.
		 * UUID is in 8-4-4-4-12 format (no braces).
		 * 
		 * e.g.	https://www.hops.org.uk/retail_qr.php?t=order&q=345c1593-389c-44a5-91dd-02bf72e67413
		 * 		https://www.hops.org.uk/retail_qr.php?t=item&q=3faa4b1d-ef72-4134-96d8-d9216678e55e
		 */
		exp: /^https:\/\/www.hops.org.uk\/retail_qr\.php\?t=(?<type>order|item)&q=(?<uuid>[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})$/i,
		handle(match) {
			if (match.groups?.uuid && match.groups?.type === "order") {
				Navigator.navigate(`/search/orders/${match.groups.uuid}`);
				return true;
			}
			else if (match.groups?.uuid && match.groups?.type === "item") {
				Navigator.navigate(`/search/orders/item/${match.groups.uuid}/order`);
				return true;
			}
			return false;
		}
	},
	{
		/*
		 * Retail Order Number
		 * 
		 * e.g. L101149
		 */
		exp: /^L(?<order>\d{5,7})$/i,
		handle(match) {
			if (match.groups?.order) {
				Navigator.navigate(`/search/orders/${match.groups.order}`);
				return true;
			}
			return false;
		}
	},
	{
		/*
		 * Gift Card
		 * 
		 * e.g. D38VF-MNINH-EY3PS-KCTS4
		 */
		exp: /^(?<code>([A-Z0-9]{5}-){3}[A-Z0-9]{5})$/i,
		handle(match) {
			if (match.groups?.code) {
				Navigator.navigate(`/inventory/giftcards/${match.groups.code}`);
				return true;
			}
			return false;
		}
	},
	{
		/*
		 * Voucher
		 * 
		 * e.g. GD4N-ZRU9-QGIC-SYHS
		 */
		exp: /^(?<code>([A-Z0-9]{4}-){3}[A-Z0-9]{4})$/i,
		handle(match) {
			if (match.groups?.code) {
				Navigator.navigate(`/inventory/vouchers/${match.groups.code}`);
				return true;
			}
			return false;
		}
	}
];

export {handleScan};

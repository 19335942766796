import {Banner, Flex, Hidden, Paper, SnackbarService, String} from "@hps/hops-react";
import {CheckoutBasketPayment, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";
import {useEffect, useState} from "react";

import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import withRegistration from "Hoc/withRegistration.js";
import withSettings from "Hoc/withSettings";
import StripeService from "Services/StripeService";

import StripePaymentIntentCaptureMethod from "./StripePaymentIntentCaptureMethod";

import PaymentProcessorBase from "../PaymentProcessorBase";

/**
 * Process a payment made with a Stripe Terminal reader.
 * 
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @param {object} Registration the POS registration to check the organisation is configured for Stripe.
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} Settings the device settings to find which reader is configured.
 * @returns JSX
 */
const PaymentProcessorStripeTerminal = ({AlwaysOffline, onPaymentFailure, onPaymentSuccess, ReducedFunctionality, Registration, RequestedAmount, Settings}) => {

	const [tenderedAmount, setTenderedAmount] = useState();
	const [progressMessage, setProgressMessage] = useState("");

	/**
	 * Runs the Stripe payment flow using the Stripe provider. This handles all the
	 * Stripe Terminal integration.
	 * @return {Promise}
	 */
	const handleOK = async () => {

		dPaymentsInProgress(true);

		const stripeService = new StripeService(
			handleError,
			handleProgressChange,
			Registration.StripeConnect?.LocationId,
			Settings.StripeTerminalSerialNumber,
			ReducedFunctionality
		);

		// Run the Stripe payment workflow for the tendered amount and then store it with the payment API
		try {
			const payment = await stripeService.RunPaymentFlow(tenderedAmount);

			if (payment?.paymentIntent?.amount) {
				await hopsStorePayment(payment);
			}
			else {
				SnackbarService.snack("Stripe Terminal payment did not complete successfully.", "error");
			}
		}
		catch (e) {
			SnackbarService.snack(StripeService.resolveErrorCode(e));
		}

		dPaymentsInProgress(false);
		dPaymentType(null);

		return;
	};


	/**
	 * Show an on screen snackbar if something goes wrong.
	 * @param {string} message The message text to show to the end user.
	 */
	const handleError = function (message) {
		setProgressMessage(null);
		SnackbarService.snack(message, "error");
	};


	/**
	 * Show a progress update as the status of the payment flow changes.
	 * @param {string} message The message text to show to the end user.
	 */
	const handleProgressChange = function (message) {
		setProgressMessage(message);
	};


	/**
	 * Send the Stripe payment result to the HOPS API for storing/validation.
	 * We also store a local copy of this in redux and send it with the Basket
	 * at checkout time.
	 * 
	 * @param {Promise} payment The Stripe payment->paymentIntent response
	 * @return {Promise|null} 
	 */
	const hopsStorePayment = async payment => {

		handleProgressChange("Receiving Confirmation from Stripe");

		/*
		 * Pass the result back to the checkout
		 * Pressing "Card" on PoS and taking payment with a Stripe Terminal, type 8 (CardHolderPresent) processor 2 (Stripe)
		 * https://gitlab.heron-web.com/hps/hops/-/issues/686#note_70795
		 */
		const result = await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentImmediateCharge: Registration.Org?.StripeConnect?.TerminalCaptureMethod !== StripePaymentIntentCaptureMethod.Manual,
				PaymentPed: Settings.StripeTerminalSerialNumber,
				PaymentProcessor: PaymentProcessorsEnum.Stripe,
				PaymentProcessorData: payment,
				PaymentType: PaymentTypes.CardHolderPresent,
				TenderedAmount: Number(tenderedAmount),
				Value: Number(payment.paymentIntent.amount)
			})
		]);

		if (result?.error) {
			handleError("Unable to update HOPS with the confirmed payment. The customer has not been charged.");
		}
		else if (result?.paymentIntent) {
			return result;
		}

		return null;
	};

	/**
	 * User pressed the Cancel button. Our parent component decides what to do.
	 * @returns {Promise}
	 */
	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};


	/**
	 * A hook to set the tendered amount to the request amount.
	 * This has the effect of pre-populating the card payment value with the whole amount.
	 */
	useEffect(() => {
		setTenderedAmount(RequestedAmount);
	}, [RequestedAmount]);


	/**
	 * Render everything
	 */
	return (
		<Flex>
			<String bold={true} str="Stripe Terminal Card Payment" variant="h5" />
			<Paper>
				<Flex alignItems="center" gap={2}>

					<Hidden hidden={Registration.Org?.StripeConnect?.TerminalCaptureMethod === StripePaymentIntentCaptureMethod.Manual}>
						<Banner severity="error" title="The customer's card will be charged immediately." />
					</Hidden>

					{!AlwaysOffline && <Hidden hidden={!ReducedFunctionality}>
						<Banner
							severity="warning" title="Stripe Terminal will attempt to use a backup server">
							Connecting to the backup server requires Internet access. You won't<br/>
							be able to take a payment if the connectivity issue is with this device.
						</Banner>
					</Hidden>}

					{progressMessage}

					<PaymentProcessorBase
						onCancel={handleCancel}
						onChange={setTenderedAmount}
						onOK={handleOK}
						RequestedAmount={RequestedAmount}
						value={tenderedAmount} />
				</Flex>
			</Paper>

			<String
				color="textSecondary"
				py={2}
				str={`Stripe Connection Provider: HOPS${ReducedFunctionality ? ` Micro (Fallback)` : ``}`}
				variant="body2"/>
		</Flex>
	);

};

export default withBasket(withReducedFunctionality(withRegistration(withSettings(PaymentProcessorStripeTerminal))));

import withBasket from "Hoc/withBasket";
import withRegistration from "Hoc/withRegistration.js";
import withSettings from "Hoc/withSettings";
import PaymentProcessorNonIntegratedCard from "Payment/Processors/PaymentProcessorNonIntegratedCard";
import RefundProcessorNonIntegratedCard from "Payment/Processors/RefundProcessorNonIntegratedCard";
import PaymentProcessorStripeTerminal from "Payment/Processors/Stripe/PaymentProcessorStripeTerminal";
import RefundProcessorStripeTerminal from "Payment/Processors/Stripe/RefundProcessorStripeTerminal";

const PaymentHandlerCardHolderPresent = props => {

	/*
	 * All PaymentTypes.CardHolderPresent payments come through this gatehouse.
	 * 
	 * Use this component to determine which processor to use for a PaymentType/PaymentMethod.
	 */

	if (props.Registration?.Org?.StripeConnect?.LocationId &&
		props.Settings?.StripeTerminalSerialNumber) {

		// Negative payments go through the refund processor
		if (props.PaymentsBalanceDue < 0) return <RefundProcessorStripeTerminal {...props} />;

		return <PaymentProcessorStripeTerminal {...props} />;
	}
	else {

		// Negative payments go through the refund processor
		if (props.PaymentsBalanceDue < 0) return <RefundProcessorNonIntegratedCard {...props} />;

		return <PaymentProcessorNonIntegratedCard {...props} />;
	}
};

export default withBasket(withRegistration(withSettings(PaymentHandlerCardHolderPresent)));

import Store from "App/Store.js";
import OrderService from "Services/OrderService";

const UploadOrdersTask = async () => {
	if (Store.getState().Orders?.Syncing) return;
	await OrderService.sync();
};


export default UploadOrdersTask;

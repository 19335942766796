import {Appv, EmptyStateGate, Flex, Hidden, Link, Loader, Paper, String, withMobile} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import React from "react";
import {connect} from "react-redux";

import EscapeHatch from "Components/EscapeHatch.js";
import dAuthUser from "Dispatchers/dAuthUser.js";
import dRegistration from "Dispatchers/dRegistration.js";
import dReset from "Dispatchers/dReset.js";
import withRegistration from "Hoc/withRegistration.js";
import Tasker from "Utils/Tasker.js";

import LoginUserSelector from "./LoginUserSelector.js";
import LoginViewNoUsers from "./LoginViewNoUsers.js";

import scss from "./LoginView.module.scss";

/**
 * Login view
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginView = props => {

	const users = props.Registration?.Users;
	const railwayName = props.Registration?.Org?.NameTrade || props.Registration?.Org?.Name;

	const handleUserLogin = user => {
		dAuthUser(user);
	};

	const renderAppVersion = () => {
		return (
			<String
				color="textSecondary"
				str={Appv} />
		);
	};


	const renderDeviceSettingsLink = () => {
		return (
			<Link
				color="textSecondary"
				label={Appv}
				uri="/device" />
		);
	};

	return (

		<Flex alignItems={props.isMobile ? undefined : "center"} gap={1} px={1} py={1}>
			<Paper alignItems="center" className={scss.root} gap={2} px={props.isMobile ? 1 : 2} py={props.isMobile ? 1 : 2} minWidth="36rem">
				<Flex gap={2}>
					<Flex alignItems="center" gap={2}>
						<Flex
							alignItems="center"
							columnar={!props.isMobile}
							gap={0}>
							<Hidden hidden={props.isMobile}>
								<EscapeHatch autoRegistration={false} onRegistration={dRegistration} onReset={dReset}>
									<div className={scss.logoContainer}>
										<img
											alt="HOPS"
											className={scss.hopsLogoImg}
											src="/icon.png" />
									</div>
								</EscapeHatch>
							</Hidden>
							<img
								alt=""
								className={scss.orgLogoImg}
								src={`${Api.baseURL}/uploads/railway_logos/${props.Registration?.Org?.Logo}`} />
							<Flex gap={0}>
								<String
									bold={true}
									centre={props.isMobile}
									component="h1"
									str={`HOPS Point of Sale`}
									variant="h5" />
								<String
									centre={props.isMobile}
									component="h1"
									str={railwayName}
									variant="h5" />
							</Flex>
						</Flex>
					</Flex>
					<EmptyStateGate emptyState={LoginViewNoUsers} isEmpty={!users?.length}>
						<LoginUserSelector onLoginSuccess={handleUserLogin} users={(users || [])} />
					</EmptyStateGate>
					<Flex
						columnar={true}
						justifyContent="space-between"
						width="100%">
						<Link
							label="Device Settings"
							uri="/device" />

						<Flex columnar={true} alignItems="center">
							{props.App?.Syncing && <Loader size={20} />}
							<Link
								disabled={props.App?.Syncing}
								label="Sync"
								onClick={() => Tasker.runAll()} />
						</Flex>
					</Flex>
				</Flex>
			</Paper>
			{(!props.registered ? renderAppVersion() : renderDeviceSettingsLink())}
		</Flex>
	);
};

export default connect(({App}) => ({App}))(withMobile(withRegistration(LoginView)));

import {Appv, Button, Flex, Link, String, Switch, TableList} from "@hps/hops-react";
import React, {useMemo, useState} from "react";

import dDevice from "Dispatchers/dDevice.js";
import dReset from "Dispatchers/dReset.js";
import withDevice from "Hoc/withDevice.js";
import withRegistration from "Hoc/withRegistration.js";

import DeviceAdminAuthGate from "./DeviceAdminAuthGate.js";
import DeviceResetDialog from "./DeviceResetDialog.js";

import scss from "./DeviceSerttingsView.module.scss";

import Strings from "./DeviceSettingsView.strings.json";


/**
 * Device settings view
 * 
 * @package HOPS
 * @subpackage Settings
 * @copyright Heritage Operations Processing Limited
 */
const DeviceSettingsView = props => {

	const [resetDialog, setResetDialog] = useState(false);

	/**
	 * Reset dialog opened.
	 *
	 * @return {void}
	 */
	const handleResetDialog = () => {
		setResetDialog(true);
	};


	/**
	 * Reset dialog closed.
	 * 
	 * @return {void}
	 */
	const handleResetDialogClose = () => {
		setResetDialog(false);
	};


	/**
	 * Touch keyboard setting toggled.
	 * 
	 * @return {void}
	 */
	const handleToggleTouchKeyboard = () => {
		dDevice({TouchKeyboard: !props.Device?.TouchKeyboard});
	};

	/**
	 * Table data definition.
	 * 
	 * @return {Array}
	 */
	const getGlobalSettings = () => {
		return [
			{
				label: Strings.controls.TouchKeyboard.label,
				content: (
					<Flex
						alignItems="center"
						columnar={true}
						gap={2.5}>
						<Switch
							checked={props.Device?.TouchKeyboard}
							onClick={handleToggleTouchKeyboard}
							color="primary" />
						<String
							color="textSecondary"
							str={Strings.controls.TouchKeyboard.caption} />
					</Flex>
				)
			},
			{
				label: Strings.controls.DeviceReset.label,
				content: (
					<Flex
						alignItems="center"
						columnar={true}
						gap={2}>
						<Button
							label={Strings.controls.DeviceReset.labelBtn}
							onClick={handleResetDialog} />
						<String
							color="error"
							str={Strings.controls.DeviceReset.caption} />
					</Flex>
				)
			}
		];
	};
	const globalSettings = getGlobalSettings();

	/**
	 * Props to apply to the `Flex` component rendered 
	 * by `TableList` as each cell's content container
	 *
	 * (We want to vertically centre the cell content
	 * while horizontally aligning items to the left.)
	 */
	const tableCellFlexProps = useMemo(() => {
		return {
			height: "100%",
			justifyContent: "center",
			alignItems: "flex-start"
		};
	}, []);

	return (
		<>
			<Flex alignItems="flex-start" gap={2} px={1} py={1}>
				<Flex gap={0.25}>
					<String
						bold={true}
						component="h1"
						str={Strings.label}
						variant="h6" />
					<String
						color="textSecondary"
						str={props.Registration?.Device?.Name} />
				</Flex>
				<DeviceAdminAuthGate>
					<Flex
						gap={2}
						maxWidth="96rem">

						<TableList
							CellFlexProps={tableCellFlexProps}
							data={[...globalSettings, ...(props.deviceSettings?.length ? props.deviceSettings : [])]}
							size="medium" />

						{props.appSettingBlocks?.map((block, key) => {
							return <Flex
								className={scss.root}
								key={key}>
								<String
									bold={true}
									component="h1"
									str={block.label}
									variant="h6" />
								<TableList
									CellFlexProps={tableCellFlexProps}
									data={block.dataTableDefinition}
									size="medium" />
							</Flex>;
						})}

					</Flex>
				</DeviceAdminAuthGate>
				<Flex gap={0.5}>
					<Link
						endArrow={true}
						label="Home"
						uri="/" />
					<String
						color="textSecondary"
						str={Appv} />
				</Flex>
			</Flex>
			<DeviceResetDialog
				onClose={handleResetDialogClose}
				onReset={dReset}
				open={resetDialog} />
		</>
	);

};

export default withRegistration(withDevice(DeviceSettingsView));

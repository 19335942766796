import {Banner, Button, Flex, Paper, String} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";

import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";


/**
 * Process a refund made with a non-integrated card terminal.
 * 
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @returns JSX
 */
const RefundProcessorNonIntegratedCard = ({RequestedAmount, onPaymentFailure, onPaymentSuccess}) => {

	const handleOK = async () => {

		/*
		 * Pass the result back to the checkout
		 * Pressing "Card" on PoS, not using the Stripe Terminal integration, type 8 (CardHolderPresent) processor 10 (HOPS)
		 * https://gitlab.heron-web.com/hps/hops/-/issues/686#note_70795
		 */
		await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.ThirdParty,
				PaymentType: PaymentTypes.CardHolderPresent,
				TenderedAmount: Number(RequestedAmount),
				Value: RequestedAmount
			})
		]);

		dPaymentType(null);

		return;
	};


	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};


	return (
		<Flex>
			<String bold={true} str="Non-Integrated Card Refund" variant="h5" />
			<Paper>
				<Flex alignItems="center">
					<Banner title="You must manually complete this action to issue the refund" severity="warning" />

					<String
						str="Use the standalone card terminal to issue a refund to the customer for the amount of" />

					<String
						str={Localisation.formatCurrency(RequestedAmount)}
						variant="h6" />

					<Button
						label="I confirm the Refund has been issued"
						onClick={handleOK}
						variant="contained" />

					<Button
						label="Cancel"
						onClick={handleCancel} />
				</Flex>
			</Paper>
		</Flex>
	);

};

export default withBasket(RefundProcessorNonIntegratedCard);

import {Button, Flex, Loader, NumericInput, SnackbarService, Stepper, String, TableList} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import React, {useState} from "react";
import StockControlledProductPicker from "Stock/StockControlledProductPicker";
import StockLocationPicker from "Stock/StockLocationPicker";

import withAuthUser from "Hoc/withAuthUser";
import withRegistration from "Hoc/withRegistration.js";
import StockControlService from "Services/StockControlService";

/**
 * Render a view to handle re-stocking items
 */
const StockRestockView = props => {

	const {
		AuthUser,
		onClose,
		Registration
	} = props;

	const deviceStockLocation = Registration?.Device?.StockLocation;

	const [activeStep, setActiveStep] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [receivingStockLocation, setReceivingStockLocation] = useState(null);
	const [stockProduct, setStockProduct] = useState(null);
	const [stockUnitQty, setStockUnitQty] = useState(null);
	const [stockUnitValue, setStockUnitValue] = useState(null);

	/**
	 * Define all the steps in this process, and their various properties.
	 */
	const steps = [
		{
			label: "Stock Location",
			backDisabled() {
				return true;
			},
			nextDisabled() {
				return !(receivingStockLocation);
			},
			renderStep() {
				return (
					<Flex>
						<String str="Where will this item be re-stocked?" variant="h6" />
						<StockLocationPicker defaultStockLocation={deviceStockLocation} onChange={handleChooseReceivingStockLocation} />
					</Flex>
				);
			}
		},
		{
			label: "Product",
			nextDisabled() {
				return !(stockProduct);
			},
			renderStep() {
				return (
					<Flex>
						<String str="Choose a product, or scan its barcode." variant="h6" />
						<StockControlledProductPicker onChange={handleChooseStockProduct} />
					</Flex>
				);
			}
		},
		{
			label: "Quantity",
			nextDisabled() {
				return true; // Rely on the Numeric Input to move next
			},
			renderStep() {
				return (
					<Flex alignItems="center">
						<String str={`Re-stock Quantity`} variant="h6" />
						<NumericInput onCancel={handlePreviousStep} label="Quantity" onSubmit={handleChooseQuantity} />
					</Flex>
				);
			}
		},
		{
			label: "Value",
			nextDisabled() {
				return true; // Rely on the Currency Input to move next
			},
			renderStep() {
				return (
					<Flex alignItems="center">
						<String str="Stock value, per unit" variant="h6" />
						<String color="textSecondary" str="This is the value of the stock unit in your inventory, not the retail price." />
						<NumericInput formatAsCurrency={true} label="Please enter an amount" onCancel={handlePreviousStep} onSubmit={handleChooseValue} />
					</Flex>
				);
			}
		},
		{
			label: "Summary",
			nextDisabled() {
				return !(stockUnitQty);
			},
			nextLabel: "Re-Stock",
			async onNextClick() {

				setIsLoading(true);

				try {

					await StockControlService.restockProduct(
						Registration?.Org?.Id,
						receivingStockLocation.id,
						stockProduct.id,
						stockUnitQty,
						stockUnitValue,
						AuthUser?.Id
					);

					SnackbarService.snack(`Successfully re-stocked ${stockUnitQty}x ${stockProduct.label}`, "success");
					onClose?.();

				}
				catch (e) {
					SnackbarService.snack(e);
				}

				setIsLoading(false);

			},
			renderStep() {

				const restockSummaryData = [
					{
						label: "Stock Location",
						content: receivingStockLocation?.label
					},
					{
						label: "Product",
						content: stockProduct?.label
					},
					{
						label: "Re-stock Units",
						content: `${stockUnitQty} units @ ${Localisation.formatCurrency(stockUnitValue)}`
					},
					{
						label: "Total Value",
						content: Localisation.formatCurrency(stockUnitQty * stockUnitValue)
					}
				];

				return <TableList data={restockSummaryData} />;

			}
		}
	];


	/**
	 * Choosing the receiving stock location
	 */
	const handleChooseReceivingStockLocation = (e, location) => {
		setReceivingStockLocation(location);
	};


	/**
	 * Choosing the stock product
	 */
	const handleChooseStockProduct = (e, product) => {
		setStockProduct(product);
	};


	/**
	 * Choosing the quantity to re-stock
	 */
	const handleChooseQuantity = qty => {
		setStockUnitQty(qty);
		handleNextStep();
	};


	/**
	 * Choosing the value of the re-stocked items
	 */
	const handleChooseValue = value => {
		setStockUnitValue(value);
		handleNextStep();
	};


	/**
	 * Move Stepper UI back one step
	 */
	const handlePreviousStep = () => {
		const prevStep = activeStep - 1;
		setActiveStep(Math.max(prevStep, 0));
	};


	/**
	 * Move Stepper UI forward one step
	 */
	const handleNextStep = () => {
		const nextStep = activeStep + 1;
		setActiveStep(Math.min(nextStep, steps.length));
	};


	return (
		<Flex gap={2}>

			<Stepper
				activeStep={activeStep}
				steps={steps} />

			{steps[activeStep]?.renderStep?.()}

			<Flex columnar={true} justifyContent="space-between">

				<Flex alignItems="center" columnar={true}>
					<Button
						disabled={steps[activeStep]?.backDisabled?.() ?? false}
						label={steps[activeStep]?.backLabel ?? "Back"}
						onClick={handlePreviousStep}
						size="large" />
					<Button
						label="Cancel"
						onClick={onClose}
						size="large"
						variant="text" />
				</Flex>

				<Flex alignItems="center" columnar={true}>
					{isLoading && <Loader size={20} />}
					<Button
						disabled={steps[activeStep]?.nextDisabled?.() ?? false}
						label={steps[activeStep]?.nextLabel ?? "Next"}
						loading={isLoading}
						onClick={steps[activeStep]?.onNextClick ?? handleNextStep}
						size="large"
						variant="contained" />
				</Flex>
			</Flex>

		</Flex>
	);

};

export default withAuthUser(withRegistration(StockRestockView));

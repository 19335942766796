import {Button, Flex, TextField} from "@hps/hops-react";
import React from "react";

import withDevice from "Hoc/withDevice.js";
import withRegistration from "Hoc/withRegistration.js";

import scss from "./DeviceAdminAuthGateForm.module.scss";

/**
 * Admin authentication gate form
 *
 * Calls `onAuthenticated()` when the user successfully authenticates.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DeviceAdminAuthGateForm extends React.PureComponent {

	/**
	 * `input`
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Device code incorrectly entered?
		 * 
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Device token input value
		 *
		 * @type {String|null}
		 */
		token: null

	};


	/**
	 * Submitted.
	 *
	 * @param {Event} e optional
	 * @return {void}
	 */
	handleSubmit = e => {

		e?.preventDefault?.();

		if (this.state.token === this.props.Registration?.Device?.RegistrationToken) {
			this.props.onAuthenticated?.();
		}
		else {
			this.setState({error: true});
			this.inputRef?.current?.select?.();
		}

	};


	/**
	 * Device token input value changed.
	 * 
	 * @param {String} token
	 * @return {void}
	 */
	handleTokenChange = token => {
		this.setState({token, error: false});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<Flex alignItems="flex-start" columnar={true} width="100%">
					<TextField
						autoFocus={true}
						className={scss.input}
						error={this.state.error}
						errorText="Incorrect device code"
						inputRef={this.inputRef}
						label="Device Code"
						maxLength={250}
						placeholderLabel={true}
						onChange={this.handleTokenChange}
						onEnter={this.handleSubmit}
						required={true}
						touchKeyboard={this.props.Device?.TouchKeyboard}
						type="password"
						value={this.state.token} />
					<Button
						label="Continue"
						type="submit"
						variant="contained" />
				</Flex>
			</form>
		);
	}

}

export default withRegistration(withDevice(DeviceAdminAuthGateForm));

import {Dialog, Flex, useUpdated, withQueryString} from "@hps/hops-react";
import Divider from "@mui/material/Divider";
import qs from "query-string";
import React, {useEffect, useState} from "react";

import withApp from "Hoc/withApp.js";

import F12Api from "./F12Api.js";
// import F12Features from "./F12Features.js";
import F12Theme from "./F12Theme.js";
import F12Version from "./F12Version.js";

import Strings from "./F12.strings.json";

/**
 * F12
 * 
 * @package HOPS
 * @subpackage F12
 * @copyright Heritage Operations Processing Limited
 */
const F12 = props => {

	/**
	 * Disabled?
	 * 
	 * @type {Boolean}
	 */
	const [disabled, setDisabled] = useState(false);

	/**
	 * Open?
	 *
	 * @type {Boolean}
	 */
	const [open, setOpen] = useState(false);


	/**
	 * Query string key
	 * 
	 * @type {String}
	 */
	const queryStringKey = "f12";


	/**
	 * Get whether we're open via a query string key.
	 * 
	 * @return {Boolean}
	 */
	const openViaQueryString = props.qs[queryStringKey];


	/**
	 * Mounted.
	 * 
	 * @return {void}
	 */
	useEffect(() => {
		updateQueryString();
		window.addEventListener("keydown", handleKey);
	}, []);


	/**
	 * Component updated.
	 *
	 * We look for the `f12` query string key.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	useUpdated(() => updateQueryString(), [props.location.search]);


	/**
	 * Unmounting.
	 * 
	 * @return {void}
	 */
	useEffect(() => {
		return () => {
			window.removeEventListener("keydown", handleKey);
		};
	}, []);

	/**
	 * Close.
	 *
	 * @return {void}
	 */
	const handleClose = () => {
		if (openViaQueryString) {
			const p = props.location.pathname;
			const q = props.qs;
			delete q[queryStringKey];
			props.history.replace(`${p}${qs.stringify(q)}`);
		}
		setOpen(false);
	};


	/**
	 * Ctrl+F12 handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleKey = e => {
		if (e.ctrlKey && (e.which === 123)) {
			handleOpen();
		}
	};


	/**
	 * Open.
	 * 
	 * @return {void}
	 */
	const handleOpen = () => {
		setOpen(true);
	};


	/**
	 * Saving data.
	 * 
	 * @return {void}
	 */
	const handleSaving = () => {
		setDisabled(true);
	};


	/**
	 * Update open state using our query string.
	 * 
	 * @return {void}
	 */
	const updateQueryString = () => {
		if (openViaQueryString) handleOpen();
	};


	return (
		<Dialog
			disabled={disabled}
			fullWidth={true}
			onClose={handleClose}
			open={open}
			title={Strings.title}>
			<Flex gap={2}>
				{/* <F12Features /> */}
				<F12Theme />
				<Divider/>
				<Flex>
					<F12Api
						disabled={disabled}
						onSave={handleSaving} />
				</Flex>
				<F12Version />
			</Flex>
		</Dialog>
	);

};

export default withApp(withQueryString(F12));

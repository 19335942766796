import {Button, EmptyStateGate, Loadable, TabPanel} from "@hps/hops-react";
import React, {useCallback, useEffect, useState} from "react";

import OrderService from "Services/OrderService";

import OrderStationeryFulfilmentBatch from "./OrderStationeryFulfilmentBatch";
import OrderStationeryFulfilmentUnbatched from "./OrderStationeryFulfilmentUnbatched";

import scss from "./OrderStationeryFulfilmentView.module.scss";

/**
 * Render a view to handle Order Stationery Fulfilment display
 */
const OrderStationeryFulfilmentView = () => {

	const TabPanelFlexProps = {className: scss.root};

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [stationeryFulfilmentData, setStationeryFulfilmentData] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);


	/**
	 * Fetch Batches
	 */
	const fetchData = useCallback(async () => {

		setLoading(true);

		await OrderService.getStationeryFulfilmentBatches()
			.then(data => setStationeryFulfilmentData(data))
			.catch(error => setError(error))
			.finally(() => setLoading(false));

	}, []);


	/**
	 * Fetch Data hook
	 */
	useEffect(() => {
		fetchData();
	}, [fetchData]);


	const tabs = [
		{
			label: "Unbatched - Post",
			render: () => <OrderStationeryFulfilmentUnbatched key={1} Items={stationeryFulfilmentData?.UnbatchedPost} OnFetchNewData={fetchData} />
		},
		{
			label: "Unbatched - Gift Pack",
			render: () => <OrderStationeryFulfilmentUnbatched key={2} Items={stationeryFulfilmentData?.UnbatchedPostGiftPack} OnFetchNewData={fetchData} />
		},
		...(stationeryFulfilmentData?.OpenBatches ? stationeryFulfilmentData.OpenBatches.map(batch => ({label: `Batch ${batch.Id}`, render: () => <OrderStationeryFulfilmentBatch Batch={batch} OnFetchNewData={fetchData} />})) : [])
	].filter(t => !t.hidden);

	const activeTab = Math.min(Math.max(selectedTab, 0), tabs.length);


	return (
		<Loadable
			error={!!error}
			errorStr={error}
			loading={loading}>
			<EmptyStateGate emptyMessage="No deliveries expected." isEmpty={!stationeryFulfilmentData}>
				<TabPanel
					FlexProps={TabPanelFlexProps}
					gap={1}
					onChange={setSelectedTab}
					preContent={
						<Button
							label="Refresh"
							onClick={fetchData} />
					}
					tabs={tabs}
					scrollButtons="auto"
					value={activeTab} />
			</EmptyStateGate>
		</Loadable>
	);

};

export default OrderStationeryFulfilmentView;

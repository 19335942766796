import {Banner, EmptyStateGate, Flex, TabPanel, UnhandledErrorView, withMobile} from "@hps/hops-react";
import {ErrorBoundary} from "@sentry/react";
import React from "react";
import {useParams} from "react-router-dom";

import EscapeHatch from "Components/EscapeHatch.js";
import ScanBox from "Components/ScanBox.js";
import dInventoryTab from "Dispatchers/dInventoryTab.js";
import withBasket from "Hoc/withBasket.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withRegistration from "Hoc/withRegistration.js";
import withUi from "Hoc/withUi.js";
import UiClock from "Ui/UiClock.js";

import FavouritesInventoryBrowser from "./Favourites/FavouritesInventoryBrowser.js";
import GiftCardInventoryBrowser from "./GiftCards/GiftCardInventoryBrowser.js";
import InventoryNoFeaturesError from "./InventoryNoFeaturesError.js";
import ProductBrowser from "./Products/ProductInventoryBrowser.js";
import TicketSessionInventoryBrowser from "./TicketsSessions/TicketSessionInventoryBrowser.js";
import TicketTravelInventoryBrowser from "./TicketsTravel/TicketTravelInventoryBrowser.js";
import VoucherInventoryBrowser from "./Vouchers/VoucherInventoryBrowser.js";

import FavouritesIcon from "@mui/icons-material/Star";

import scss from "./InventoryBrowser.module.scss";


/**
 * Inventory browser
 * 
 * Renders all available inventory panels as a tabbed layout.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const InventoryBrowser = props => {

	const {InventoryView} = useParams();
	const TabPanelFlexProps = {className: scss.root};

	const tabs = [
		{
			label: "Travel",
			hidden: !props.hasRegistrationFeature("Tickets"),
			component: TicketTravelInventoryBrowser,
			slug: "tickets"
		},
		{
			label: "Events",
			hidden: !props.hasRegistrationFeature("Tickets"),
			component: TicketSessionInventoryBrowser,
			slug: "events"
		},
		{
			label: "Products",
			hidden: !props.hasRegistrationFeature("OrgProducts"),
			component: ProductBrowser,
			slug: "products"
		},
		{
			label: "Vouchers",
			hidden: !props.hasRegistrationFeature("VoucherSales") || props.AlwaysOffline,
			component: VoucherInventoryBrowser,
			slug: "vouchers"
		},
		{
			label: "Gift Cards",
			hidden: !props.hasRegistrationFeature("GiftCards") || props.AlwaysOffline,
			component: GiftCardInventoryBrowser,
			slug: "giftcards"
		},
		{
			icon: FavouritesIcon,
			component: FavouritesInventoryBrowser,
			hidden: !props.hasRegistrationFeature("Tickets")
		}
	].filter(t => !t.hidden);

	/** See if an inventory view is passed in the URL */
	let inventoryTabIndex = 0;

	if (InventoryView) {
		inventoryTabIndex =	tabs.findIndex(t => {
			return t.slug === InventoryView;
		});
	}

	const activeTab = inventoryTabIndex > 0 ? inventoryTabIndex : Math.min(Math.max(props.Ui.InventoryTab, 0), tabs.length);

	const renderBrowserWarning = () => {
		return (
			<Flex gap={0}>
				<Banner title="Check your Web Browser Version" severity="error" str="HOPS Point of Sale only supports the 3 most recent versions. Please ensure your device's web browser is fully updated. " />
				<UnhandledErrorView EscapeHatch={EscapeHatch} />
			</Flex>
		);
	};

	if (!tabs.length) {
		return (
			<Flex px={1} py={1}>
				<EmptyStateGate isEmpty={true} />
			</Flex>
		);
	}

	return <>
		<ErrorBoundary fallback={renderBrowserWarning}>
			<TabPanel
				FlexProps={TabPanelFlexProps}
				gap={1}
				noTabsState={InventoryNoFeaturesError}
				onChange={dInventoryTab}
				preContent={<ScanBox />}
				postContent={!(props.isMobile) ? <UiClock /> : undefined}
				tabs={tabs}
				TabsProps={
					{
						sx: {

							/**
							 * This is a bit rubbish but the MUI
							 * tab component is fighting fixed with
							 */
							width: props.isMobile ? "23em" : undefined

						}
					}
				}
				value={activeTab}
				variant="scrollable" />
		</ErrorBoundary>
	</>;

};

export default withBasket(withMobile(withReducedFunctionality(withRegistration(withUi(InventoryBrowser)))));

/**
 * A list of Org IDs for named railways
 */
const KnownOrganisations = {
	HOPS_HERITAGE_RAILWAYS: 6, // HOPS HQ
	DEMO_RAILWAY: 33,
	HOPS_RAILWAY: 504 // Cyprus
};

export default KnownOrganisations;

import DefaultGatewayConfig from "Gateway/DefaultGatewayConfig.json";

const State = {

	AdminUser: {

		/**
		 * Active authenticated user ID
		 * 
		 * @type {Integer|null}
		 */
		Id: null,

		/**
		 * Permissions index
		 *
		 * @type {Object|null}
		 */
		Permissions: null

	},

	/**
	 * Container for a single reducer that handles all the main app state.
	 * 
	 * @type {Object}
	 */
	App: {

		/**
		 * API Base URI
		 * 
		 * @type {Date}
		 */
		ApiBaseUri: null,

		/**
		 * Features to enable from the Registration API
		 * 
		 * @type {Array}
		 */
		Features: [],

		/**
		 * Features supported by the App
		 * 
		 * @type {Array}
		 */
		FeaturesAvailable: [],

		/**
		 * Feature names that have been forced-enabled (ticked)
		 *
		 * @type {Array}
		 */
		FeaturesForced: [],

		/**
		 * Timestamp of last failed heartbeat (either directly, or indirectly through another API call)
		 * 
		 * @type {Date}
		 */
		LastHeartbeatErrorTime: null,

		/**
		 * Timestamp of last successful heartbeat (either directly, or indirectly through another API call)
		 * 
		 * @type {Date}
		 */
		LastHeartbeatSuccessTime: null,

		/**
		 * Timestamp of last attempted heartbeat (either directly, or indirectly through another API call)
		 * 
		 * @type {Date}
		 */
		LastHeartbeatTryTime: null,

		/**
		 * Syncing data?
		 *
		 * @type {Boolean}
		 */
		Syncing: false,

		/**
		 * App update available?
		 *
		 * @type {Boolean}
		 */
		UpdateAvailable: false,

		/**
		 * Did we just install an update?
		 * 
		 * @type {Boolean}
		 */
		UpdateInstalled: false
	},

	AuthUser: {

		/**
		 * Active authenticated user ID
		 * 
		 * @type {Integer|null}
		 */
		Id: null,

		/**
		 * Permissions index
		 *
		 * @type {Object|null}
		 */
		Permissions: null

	},

	/**
	 * Everything to do with the basket, as a single object (client-side only)
	 * 
	 * @type {Object}
	 */
	Basket: {

		/**
		 * Basket claim data
		 *
		 * Contains the items claimed in the latest basket addition 
		 * operation and the outcomes of those claims (claims/errors).
		 *
		 * @type {Object|null}
		 */
		ClaimData: {

			/**
			 * Items that were claimed
			 *
			 * @type {Array|null} Basket item objects
			 */
			Items: null,

			/**
			 * Result of the claim
			 *
			 * @type {Object|null} Item claim result object (`Claims`/`Errors`)
			 */
			Result: null

		},

		/**
		 * Basket customer details
		 *
		 * @type {Object|null}
		 */
		CustomerDetails: {

			/**
			 * First name
			 *
			 * @type {String|null}
			 */
			Fname: null,

			/**
			 * Last name
			 *
			 * @type {String|null}
			 */
			Sname: null,

			/**
			 * Email address
			 *
			 * @type {String|null}
			 */
			Email: null,

			/**
			 * Gift Aid Declaration
			 */
			GiftAid: null,

			/**
			 * Phone number
			 *
			 * @type {String|null}
			 */
			Phone: null,

			/**
			 * Billing address
			 *
			 * @type {Object|null}
			 */
			Address: {

				/**
				 * Address line
				 *
				 * @type {String|null}
				 */
				Address: null,

				/**
				 * City/town
				 *
				 * @type {String|null}
				 */
				City: null,

				/**
				 * County/state
				 *
				 * @type {String|null}
				 */
				County: null,

				/**
				 * Postcode
				 *
				 * @type {String|null}
				 */
				Postcode: null,

				/**
				 * Country code (ISO-3166-1 Alpha-2)
				 * 
				 * @type {String|null}
				 */
				Country: null

			}

		},

		/**
		 * Basket discounts
		 *
		 * All the discounts applied to the basket, confirmed by the server.
		 *
		 * @type {Array<Object>}
		 */
		Discounts: [],

		/**
		 * Basket identity
		 * 
		 * @type {Object}
		 */
		Identity: {

			/**
			 * Basket ID
			 *
			 * @type {Integer|null}
			 */
			Id: null,

			/**
			 * Basket Offline UUID (will become Order UUID)
			 *
			 * @type {string}
			 */
			OfflineBasketUuid: null,

			/**
			 * Expiration time
			 *
			 * (Complete checkout before this time.)
			 * 
			 * @type {Integer|null}
			 */
			Expiry: null

		},

		/**
		 * Basket items
		 *
		 * @type {Array}
		 */
		Items: [],

		/**
		 * Basket loading
		 *
		 * `true` when awaiting confirmation of item additions/removals etc.
		 * 
		 * @type {Boolean}
		 */
		Loading: false,

		/**
		 * Questions to ask at checkout
		 *
		 * @type {Object|null}
		 */
		Questions: null,

		/**
		 * Answers to the checkout questions
		 *
		 * @type {Object|null}
		 */
		QuestionsAnswers: null,

		/**
		 * Answers to the checkout questions where answers have been declined
		 *
		 * @type {Object|null}
		 */
		QuestionsAnswersDeclined: null,

		/**
		 * Payment type ID (currently selected)
		 * 
		 * @type {Integer|null}
		 */
		PaymentType: null,

		/**
		 * List of payments taken for the current order
		 *
		 * @type {Array}
		 */
		Payments: [],

		/**
		 * Payments loading
		 *
		 * `true` when awaiting some kind of payment in progress (e.g. a Card Payment).
		 * 
		 * @type {Boolean}
		 */
		PaymentsInProgress: false
	},

	/**
	 * Things saved for offline use
	 * 
	 * @type {Object}
	 */
	Cache: {

		/**
		 * A cache of today's fares.
		 * 
		 * The tickets store isn't persisted (so you can clear your ticket
		 * selection on a refresh) so we store it here
		 * 
		 * @type {Object}
		 */
		Fares: {}

	},

	/**
	 * Device Settings
	 * 
	 * @type {Object}
	 */
	Device: {

		/**
		 * What type of device are we registered as?
		 *
		 * @type {Integer|null} The DeviceTypes id of the current device registration
		 */
		Type: null,


		/**
		 * Enable the touch keyboard?
		 *
		 * @type {Boolean}
		 */
		TouchKeyboard: false

	},

	/**
	 * HOPSP Gateway integration settings
	 *
	 * @type {Object}
	 */
	Gateway: {

		/**
		 * Active integration?
		 *
		 * @type {Boolean}
		 */
		Active: false,

		/**
		 * Gateway server hostname
		 *
		 * @type {String|null}
		 */
		Host: DefaultGatewayConfig.Host,

		/**
		 * Gateway server port
		 *
		 * @type {Integer|null}
		 */
		Port: DefaultGatewayConfig.Port,

		/**
		 * Gateway server security token
		 *
		 * @type {String|null}
		 */
		SecurityToken: null,

		/**
		 * Stationery printer ID
		 *
		 * @type {String|null}
		 */
		StationeryPrinterId: null,

		/**
		 * Use Gateway to handle receipts?
		 *
		 * (Uses the native (old) print integration otherwise.)
		 * 
		 * @type {Boolean}
		 */
		ReceiptsEnabled: false

	},


	Orders: {

		/**
		 * Recently placed orders from this POS, for easy recall
		 * 
		 * @type {Array<Object>}
		 */
		History: [],

		/**
		 * Is there an upload sync in progress
		 * 
		 * @type {bool}
		 */
		Syncing: false,


		/**
		 * Queue of orders that need to be uploaded
		 * 
		 * @type {Array<Object>}
		 */
		UploadQueue: []

	},

	/**
	 * Registration data.
	 * This is initialized as an empty object.
	 * The tree shape is different depending on what the client application does.
	 *
	 * @type {Object}
	 */
	Registration: {},

	/**
	 * Pos Settings
	 * 
	 * @type {Object}
	 */
	Settings: {

		/**
		 * Automatically print receipts?
		 *
		 * @type {Boolean}
		 */
		AutomaticReceiptPrinting: true,

		/**
		 * Receipts characters-per-line (width)
		 *
		 * @type {Integer}
		 */
		ReceiptsCpl: 48

	},

	/**
	 * Stock Control
	 */
	StockControl: {

		/**
		 * In-progress deliveries
		 * 
		 * @type {Array<Object>}
		 */
		Deliveries: {}

	},

	/**
	 * Theme
	 * 
	 * @type {Object}
	 */
	Theme: {

		/**
		 * Accent colour
		 * 
		 * @type {String|null} Hex
		 */
		AccentColour: "#299c39",

		/**
		 * Accent colour (secondary)
		 * 
		 * @type {String|null} Hex
		 */
		AccentColourSecondary: null,

		/**
		 * Google Fonts name
		 *
		 * @type {String|null}
		 */
		FontFamily: null,
		FontFamilyGoogle: null,

		/**
		 * Material UI base theme mode
		 * 
		 * auto = user agent preference
		 * light = force light
		 * dark = force dark
		 * 
		 * @type {String}
		 */
		Mode: "auto"

	},

	/**
	 * Tickets/selection state data.
	 *
	 * @type {Object}
	 */
	Tickets: {

		/**
		 * Active tickets selection
		 * 
		 * @type {Object}
		 */
		Selection: {

			/**
			 * Date
			 *
			 * @type {String|null} YYYY-MM-DD
			 */
			Date: null,

			/**
			 * Class
			 * 
			 * @type {Object|null}
			 */
			Class: null,

			/**
			 * Fare type
			 *
			 * @type {Object|null}
			 */
			FareType: null,

			/**
			 * Journey type ID
			 * 
			 * @type {Integer|null}
			 */
			JourneyType: null,

			/**
			 * Timing Point A
			 *
			 * @type {Object|null}
			 */
			TimingPointA: null,

			/**
			 * Timing Point B
			 *
			 * @type {Object|null}
			 */
			TimingPointB: null,

			/**
			 * Timing Point C
			 *
			 * @type {Object|null}
			 */
			TimingPointC: null,

			/**
			 * Counts of tickets to order by type ID
			 * 
			 * @type {Object|null}
			 */
			TypeCounts: null

		},

		/**
		 * Tickets selection key
		 *
		 * Incremented each time `TicketsSelection` is manually changed.
		 *
		 * @type {Integer}
		 */
		SelectionKey: 0,

		/**
		 * Tickets journey selection key
		 *
		 * Incremented to reset the ticket browser journey selection when needed.
		 *
		 * @type {Integer}
		 */
		JourneySelectionKey: 0
	},

	/**
	 * UI state data.
	 *
	 * @type {Object}
	 */
	Ui: {

		/**
		 * Active tab in the Central Admin browser
		 * 
		 * @type {Integer}
		 */
		CentralAdminTab: 0,

		/**
		 * Active tab in the CS browser
		 * 
		 * @type {Integer}
		 */
		CustomerServicesTab: 0,

		/**
		 * Active tab in the inventory browser
		 * 
		 * @type {Integer}
		 */
		InventoryTab: 0,

		/**
		 * Logout dialog open?
		 *
		 * @type {Boolean}
		 */
		LogoutDialog: false,

		/**
		 * Does the app think it's online ?
		 *
		 * @type {Boolean}
		 */
		OnlineApp: true,

		/**
		 * Is the network available ?
		 *
		 * @type {Boolean}
		 */
		OnlineNetwork: true,

		/**
		 * Questions dialog open?
		 *
		 * @type {Boolean}
		 */
		QuestionsDialog: false,

		/**
		 * Seat reservation dialog state
		 * 
		 * @type {String|Null} Basket item UUID to show seat reservations for
		 */
		SeatReservationDialog: null,

		/**
		 * Active tab in the Stock browser
		 * 
		 * @type {Integer}
		 */
		StockTab: 0,

		/**
		 * Supervisor login dialog open?
		 *
		 * @type {Boolean}
		 */
		SupervisorLoginDialog: false,

		/**
		 * Next item is a refund (negative Qty) ?
		 *
		 * @type {Boolean}
		 */
		Refund: false,

		/**
		 * Stationery printing?
		 *
		 * @type {Boolean}
		 */
		StationeryPrinting: false

	}
};

export default State;

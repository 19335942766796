import {Bghost, SnackbarService} from "@hps/hops-react";
import {DeviceService, DeviceTypes} from "@hps/hops-sdk-js";

import Store from "App/Store.js";
import dRegistration from "Dispatchers/dRegistration";
import dReset from "Dispatchers/dReset";


const UpdateRegistrationTask = async () => {

	const token = Store.getState().Registration?.Device?.RegistrationToken;

	/**
	 * Registration task only runs if a DeviceType is configured in HOPSApplication
	 * We might be hosting an app that isn't a device.
	 */

	if (token) {
		try {

			const registration = await Bghost.exec(DeviceService.getDeviceRegistration(DeviceTypes.POS, token));

			dRegistration(registration);
			// dFeatures(registration.Features);

		}
		catch (e) {

			/**
			 * Device token not found (404)
			 *
			 * OR
			 * 
			 * Device token no longer valid (410)
			 *
			 * OR
			 *
			 * Org's PoS license no longer valid (403)
			 */
			if ([403, 404, 410].includes(e?.response?.status)) {

				dReset();

				SnackbarService.snack(
					"The device was reset because its registration is no longer valid.",
					"warning",
					{
						persist: true,
						withCloseButton: true
					}
				);

			}

		}
	}

};


export default UpdateRegistrationTask;

import {Button, Flex, Section, String} from "@hps/hops-react";
import {memo, useCallback, useState} from "react";

import withDevice from "Hoc/withDevice";

import CashRefundDialog from "./OrderCashRefundDialog.js";
import VoucherRefundDialog from "./OrderVoucherRefundDialog.js";

import scss from "./SettleOrderPaymentViewNewRefundSection.module.scss";


const SettleOrderPaymentViewNewRefundSection = memo(({
	amountOwing,
	onRefunded,
	targetOrderId
}) => {

	const [cashDialogOpen, setCashDialogOpen] = useState(false);
	const [voucherDialogOpen, setVoucherDialogOpen] = useState(false);

	const handleOpenCashDialog = useCallback(() => {
		setCashDialogOpen(true);
	}, []);

	const handleCloseCashDialog = useCallback(() => {
		setCashDialogOpen(false);
	}, []);

	const handleOpenVoucherDialog = useCallback(() => {
		setVoucherDialogOpen(true);
	}, []);

	const handleCloseVoucherDialog = useCallback(() => {
		setVoucherDialogOpen(false);
	}, []);


	return (
		<Section
			title="Refund in Cash or as New Voucher">
			<Flex
				px={1}
				py={1}>
				<div
					className={scss.controlRow}>
					<Button
						disabled={(amountOwing <= 0)}
						label="Issue Cash Refund"
						onClick={handleOpenCashDialog}
						variant="outlined" />
					<String
						gap={0.5}
						str="Use this option if giving the customer a refund in cash."
						variant="body2" />
				</div>
				{/* <Hidden hidden={Device?.Type === DeviceTypes.POS}> */}
				<div
					className={scss.controlRow}>
					<Button
						disabled={(amountOwing <= 0)}
						label="Issue Voucher Refund"
						onClick={handleOpenVoucherDialog}
						variant="outlined" />
					<String
						gap={0.5}
						str={[
							"Use this option to give the customer a new voucher of the required amount.",
							"(To refund to an existing voucher used to make payment, use the options below.)"
						]}
						variant="body2" />
				</div>
				{/* </Hidden> */}
			</Flex>
			<CashRefundDialog
				amountOwing={amountOwing}
				open={cashDialogOpen}
				onClose={handleCloseCashDialog}
				onComplete={onRefunded}
				targetOrderId={targetOrderId} />
			<VoucherRefundDialog
				amountOwing={amountOwing}
				open={voucherDialogOpen}
				onClose={handleCloseVoucherDialog}
				onComplete={onRefunded}
				targetOrderId={targetOrderId} />
		</Section>
	);

});

export default withDevice(SettleOrderPaymentViewNewRefundSection);

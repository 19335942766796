import {EmptyStateGate, Flex, TicketBuilder, withMobile} from "@hps/hops-react";
import {ErrorBoundary} from "@sentry/react";
import React, {useState} from "react";

import dTicketsJourneySelectionKeyIncrement from "Dispatchers/dTicketsJourneySelectionKeyIncrement.js";
import dTicketsSelection from "Dispatchers/dTicketsSelection.js";
import dTicketsSelectionMerge from "Dispatchers/dTicketsSelectionMerge.js";
import withInventory from "Hoc/withInventory.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withTickets from "Hoc/withTickets.js";
import scss from "Inventory/InventoryBrowserWithToolbar.module.scss";
import BasketService from "Services/BasketService.js";

import TicketTravelInventoryResults from "./TicketTravelInventoryResults.js";

/**
 * Ticket inventory browser
 *
 * Renders available travel tickets
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const TicketTravelInventoryBrowser = props => {

	const [addingToBasket, setAddingToBasket] = useState(false);

	/**
	 * Adding tickets to the basket.
	 *
	 * @param {Array<Object>} items CheckoutBasketItem-like objects
	 * @return {void}
	 */
	const handleAddToBasket = async items => {

		setAddingToBasket(true);

		try {
			await BasketService.addItems(items);
		}
		catch (e) {
			// ...
		}

		setAddingToBasket(false);

	};


	/**
	 * Resetting the selection to its initial state.
	 * 
	 * @return {void}
	 */
	const handleReset = () => {
		dTicketsSelection({});
		dTicketsJourneySelectionKeyIncrement();
	};

	const canSellTravelOffline = props.hasRegistrationFeature("TravelOffline");
	const emptyMessage = props.ReducedFunctionality ? "Unavailable - Device is offline." : undefined;
	const isVisible = props.ReducedFunctionality ? canSellTravelOffline : props.Inventory?.Tickets;

	const operatingDays = (props.Inventory?.Tickets?.OperatingDays || []);
	const dayStyles = operatingDays.map(day => ({
		date: day,
		color: "black",
		backgroundColor: "lightgreen"
	}));

	return (
		<EmptyStateGate emptyMessage={emptyMessage} isEmpty={!isVisible}>
			<Flex
				className={props.isMobile ? scss.containerReduced : scss.root}
				columnar={!props.isMobile}
				width="100%">
				<TicketBuilder
					classes={props.Inventory?.Tickets?.Classes}
					dayStyles={dayStyles}
					enabledJourneyTypes={props.Inventory?.Tickets?.Travel?.EnabledJourneyTypes}
					elevated={false}
					fareTypes={props.Inventory?.Tickets?.FareTypes}
					hideJourneyTypes={props.Registration?.Inventory?.Travel?.AlwaysHideJourneyType}
					hideJourneyTypeDescription={true}
					hideTravelDate={props.ReducedFunctionality}
					onChange={dTicketsSelectionMerge}
					onReset={handleReset}
					operatingDays={operatingDays}
					selection={props.TicketsSelection}
					timingPoints={props.Inventory?.Tickets?.TimingPoints}
					types={(props.Inventory?.Tickets?.Types || []).filter(t => t.ShowNormalTravel)}
					withClassSelect={true}
					withResetButton={true} />
				<ErrorBoundary>
					<TicketTravelInventoryResults
						addingToBasket={addingToBasket}
						onAddToBasket={handleAddToBasket} />
				</ErrorBoundary>
			</Flex>
		</EmptyStateGate>
	);

};

export default withInventory(withMobile(withReducedFunctionality(withTickets(TicketTravelInventoryBrowser))));

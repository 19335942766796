import {OrderableTypes} from "@hps/hops-sdk-js";

/*
 * This should probably go in hops-sdk-js, but only POS
 * can print tickets at the moment so it can live here for now
 */

const getOrderableTypeLabel = mode => {
	switch (mode) {
		case OrderableTypes.Addon:
			return "Stock Item";
		case OrderableTypes.DeliveryMethod:
			return "Delivery";
		case OrderableTypes.GiftCardInstance:
			return "Gift Card Redemption";
		case OrderableTypes.GiftCardInventory:
			return "Gift Card";
		case OrderableTypes.Membership:
			return "Membership";
		case OrderableTypes.PaymentMethod:
			return "Payment";
		case OrderableTypes.ProductHOPS:
			return "HOPS Item";
		case OrderableTypes.SeatReservation:
			return "Seat Reservation";
		case OrderableTypes.Shares:
			return "Shares";
		case OrderableTypes.System:
			return "System Item";
		case OrderableTypes.TicketSession:
			return "Event Ticket";
		case OrderableTypes.TicketTravel:
			return "Travel Ticket";
		case OrderableTypes.VoucherSale:
			return "Voucher";
		default:
			return "Unknown";
	}
};

const allowsRefund = mode => {
	return [
		OrderableTypes.Membership,
		OrderableTypes.Addon,
		OrderableTypes.TicketTravel,
		OrderableTypes.TicketSession,
		OrderableTypes.VoucherSale,
		OrderableTypes.Shares
	].includes(mode);
};

const isPrintableByCentralAdmin = item => {
	return !!item.Orderable?.StationeryTemplateId;
};

const isPrintableByRailway = mode => {
	return [
		OrderableTypes.TicketTravel,
		OrderableTypes.TicketSession
	].includes(mode);
};

export {
	allowsRefund,
	getOrderableTypeLabel,
	isPrintableByCentralAdmin,
	isPrintableByRailway
};
